import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Container,
  Row,
  Card,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardBody,
} from "reactstrap"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import { ToastContainer, toast } from "react-toastify"
import Title from "common/Title"
const NotesUpload = () => {
  const live_Url = "https://marcopolo.prisms.in"
  const dispatch = useDispatch()
  const [classList, setClassList] = useState([])
  const [Loading, setLoading] = useState(false)
  const [schoolName, setSchoolName] = useState()
  const [subjectList, setSubjectList] = useState([])
  const [currentDate, setCurrentDate] = useState("")
  const [isSubmited, setIsSubmited] = useState(false)
  const [Academic, setAcademic] = useState([])
  const [AcadID, setAcadId] = useState([])
  const sid = localStorage.getItem("sid").replace(/"/g, "")
  const staff_id = localStorage.getItem("user_id").replace(/"/g, "")
  const [uploadNotes, setUploadNotes] = React.useState({
    sid: "",
    staff_id: "",
    classdiv_id: "",
    subject_id: "",
    topicName: "",
    description: "",
    files: [],
  })

  const [errors, setErrors] = useState({
    classid: "",
    subject_id: "",
    topicName: "",
    description: "",
  })

  const bucketName = `topschool-attachments/${schoolName}prisms/${Academic}/TeacherNotes/upload`

  const handleFileChange = event => {
    const selectedFiles = event.target.files
    setUploadNotes({
      ...uploadNotes,
      files: Array.from(selectedFiles),
    })
  }

  const onHandleChange = (e, fieldName) => {
    if (fieldName === "topicName") {
      setErrors(prev => ({
        ...prev,
        topicName: "",
      }))
    }

    if (fieldName === "description") {
      setErrors(prev => ({
        ...prev,
        description: "",
      }))
    }

    setUploadNotes(prevState => ({
      ...prevState,
      [fieldName]: e.target.value,
    }))
  }

  const getSchoolName = async () => {
    const data = {
      fun_name: "getSchoolURL",
      sid: sid,
    }
    await axios({
      method: "POST",
      url: "https://topschool.prisms.in/rest/index.php/user_list.json",
      data,
    }).then(response => {
      setSchoolName(response.data)
    })
  }

  useEffect(() => {
    getSchoolName()
    const getAcadId = async () => {
      try {
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/tscommon/getCurrentAcad",
          data: { sid: sid },
        }).then(response => {
          setAcademic(response.data[0].academics)
          setAcadId(response.data[0].acadid)
        })
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    getAcadId()
    axios({
      method: "POST",
      url: "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
      data: {
        fun_name: "getAccessClass",
        sid: sid,
        staff_id: staff_id,
      },
    })
      .then(response => {
        setClassList(response.data.result)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const selectClassIdHandler = e => {
    if (e.target.value) {
      setErrors(prev => ({
        ...prev,
        classid: "",
      }))
      setUploadNotes(prevState => ({
        ...prevState,
        classdiv_id: e.target.value,
      }))
      axios({
        method: "POST",
        url: "https://topschooldev.prisms.in/rest/index.php/staff_list.json",
        data: {
          fun_name: "getAccessClassSubject",
          sid: sid,
          staff_id: staff_id,
          div_id: e.target.value,
        },
      })
        .then(response => {
          setSubjectList(response.data.result)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const selectSubjectHandler = e => {
    setErrors(prev => ({
      ...prev,
      subject_id: "",
    }))
    setUploadNotes(prevState => ({
      ...prevState,
      subject_id: e.target.value,
    }))
  }

  // Validation logic
  const validateForm = () => {
    const newErrors = {
      topicName: "",
      description: "",
      classdiv_id: "",
      subject_id: "",
    }
    let isValid = true

    if (!uploadNotes.classdiv_id) {
      newErrors.classid = "Select class"
      isValid = false
    }
    if (!uploadNotes.subject_id) {
      newErrors.subject_id = "Select subject"
      isValid = false
    }
    if (!uploadNotes.topicName) {
      newErrors.topicName = "Topic Name is required"
      isValid = false
    }

    if (!uploadNotes.description) {
      newErrors.description = "Description is required"
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm()) {
      // Proceed with form submission logic
      setLoading(true)
      let formData = new FormData()

      formData.append("sid", sid)
      formData.append("staff_id", staff_id)
      formData.append("classdiv_id", uploadNotes.classdiv_id)
      formData.append("subject_id", uploadNotes.subject_id)
      formData.append("topicName", uploadNotes.topicName)
      formData.append("description", uploadNotes.description)
      formData.append("cdate", currentDate)
      formData.append("bucketName", bucketName)

      const selectedFiles = uploadNotes.files
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i])
      }

      axios({
        method: "POST",
        url: `https://marcopolo.prisms.in/api/notification/notes/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(res => {
          setIsSubmited(true)
          setLoading(false)
          toast.success("Notes uploaded successfully")
        })
        .catch(error => {
          setLoading(false)
          console.log("Form submission error:", error)
        })
    }
  }

  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setCurrentDate(formattedDate)
  }, [])

  useEffect(() => {
    if (isSubmited) {
      setUploadNotes({
        sid: "",
        staff_id: "",
        classdiv_id: "",
        subject_id: "",
        topicName: "",
        description: "",
        files: [],
      })
    }
  }, [isSubmited])

  if (Loading) {
    return <Spinners />
  }
  return (
    <div className="page-content">
      <Container>
        <Card className="shadow-lg rounded-lg">
          <CardBody>
          <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Upload Notes
            </h1>
            <Row className="mb-4">
         <Col xl="12">
            <Form onSubmit={handleSubmit}>
              <FormGroup row>
                <Col md={10}>
                  <Input
                    type="date"
                    name="date"
                    id="date"
                    value={currentDate}
                    onChange={e => setCurrentDate(e.target.value)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md={10}>
                  <Input
                    type="select"
                    name="class"
                    id="class"
                    onChange={selectClassIdHandler}
                  >
                    <option value="">Select Class</option>
                    {classList.map((data, idx) => (
                      <option key={idx} value={data.id}>
                        {data.div_name}
                      </option>
                    ))}
                  </Input>
                  {errors.topicName && (
                    <div className="text-danger">{errors.classid}</div>
                  )}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md={10}>
                  <Input
                    type="select"
                    name="subject"
                    id="subject"
                    onChange={selectSubjectHandler}
                  >
                    <option value="">Select Subject</option>
                    {subjectList.map((data, idx) => (
                      <option key={idx} value={data.id}>
                        {data.subject}
                      </option>
                    ))}
                  </Input>
                  {errors.topicName && (
                    <div className="text-danger">{errors.subject_id}</div>
                  )}
                </Col>
              </FormGroup>

              <FormGroup>
                <Label for="topicName">Topic Name</Label>
                <Input
                  type="text"
                  name="topicName"
                  id="topicName"
                  value={uploadNotes.topicName}
                  onChange={e => onHandleChange(e, "topicName")}
                />
                {errors.topicName && (
                  <div className="text-danger">{errors.topicName}</div>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={uploadNotes.description}
                  onChange={e => onHandleChange(e, "description")}
                />
                {errors.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Upload File</Label>
                <Input type="file" multiple onChange={handleFileChange} />
              </FormGroup>

              <Button color="primary">Submit</Button>
            </Form>
          </Col>
         </Row>
          </CardBody>
        
        </Card>
      </Container>
      <ToastContainer />
    </div>
  )
}

export default NotesUpload
