import React from "react"
import ResuableComponent from "../../../common/ResuableHome"
const Home = () => {
  return (
    <div>
      <ResuableComponent
        title={"Assignment"}
        r1={"/create-assignment"}
        r2={"/assignment-report"}
        // r3={"/students-assignment"}
        t1={"Create Assignment"}
        t2={"Assignment Report"}
        // t3={"Submitted Assignment"}
      />
    </div>
  )
}

export default Home
