import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import { GET_ATTEND_REPORT, GET_ATTENDANCE_DIV, GET_STUDLIST, MARK_ABSENT_STUD } from "./constants";
import { getAttendanceDivAPI, getAttendReportApi, getStudListAPI, markAbsentStudAPI } from "./api";
import { getAttendanceDivFailed, getAttendanceDivSuccess, getAttendReportFailed, getAttendReportSuccess, getAttendStudListFailed, getAttendStudListSuccess, markAbsentStudFailed, markAbsentStudSuccess, } from "./action";


function* getAttendanceDivAsync() {
    try {
        const response = yield call(getAttendanceDivAPI)
        if (response) {
            yield put(getAttendanceDivSuccess(response.data))
        } else {
            throw response;
        }

    } catch (error) {
        // console.log("error", error)
        yield put(getAttendanceDivFailed(error.response.data || "Unable to get Division please try again"))
    }
}


function* getStudListAsync(action) {
    try {
        const response = yield call(getStudListAPI, action.payload)
        if (response.status == 200) {
            // console.log(response.data[1].absent_students)
            yield put(getAttendStudListSuccess(response.data))
        } else {
            throw response;
        }

    } catch (error) {
        // console.log("error", error)
        yield put(getAttendStudListFailed(error.response.data || "Unable to get Division please try again"))
    }
}


function* markAbsentStudAsync(action) {
    try {
        const response = yield call(markAbsentStudAPI, action.payload)
        if (response) {
            yield put(markAbsentStudSuccess("Attendance marked Successfully"))
        } else {
            throw response;
        }

    } catch (error) {
        // console.log("error", error)
        yield put(markAbsentStudFailed(error.response.data || "Unable to update attendance, please try again"))
    }
}


function* getAttendReportAsync(action) {
    try {
        const response = yield call(getAttendReportApi, action.payload)
        if (response) {
            yield put(getAttendReportSuccess(response.data))
        } else {
            throw response;
        }

    } catch (error) {
        // console.log("error", error)
        yield put(getAttendReportFailed(error.response.data || "Unable to update attendance, please try again"))
    }
}

function* AttendanceSaga() {

    yield takeEvery(GET_ATTENDANCE_DIV, getAttendanceDivAsync)
    yield takeEvery(GET_STUDLIST, getStudListAsync)
    yield takeEvery(MARK_ABSENT_STUD, markAbsentStudAsync)
    yield takeEvery(GET_ATTEND_REPORT, getAttendReportAsync)
   //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default AttendanceSaga;