import React, { useState, useEffect } from "react"
import axios from "axios"
import { Card, CardBody, Col, Container, Input, Row, Spinner } from "reactstrap"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"

const ResuableComponent = ({ data }) => {
  return (
    <div>
      {data &&
        data.map((item, index) => {
          return (
            <Card
              key={index}
              className="shadow-lg rounded-sm p-2"
            >
              <div className="d-flex flex-row justify-content-between">
                <span style={{ fontWeight: 600 }} className="mt-3 w-50">
                  Class:
                </span>
                <span className="mt-3 w-50 text-md-end">{item.div_name}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span style={{ fontWeight: 600 }} className="mt-3 w-50">
                  Subject:
                </span>
                <span className="mt-3 w-50 text-md-end">{item.subjects}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span style={{ fontWeight: 600 }} className="mt-3 w-50">
                  Date:
                </span>
                <span className="mt-3 w-50 text-md-end">
                  {moment(item.created_date).format("DD-MM-YYYY")}
                </span>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <span style={{ fontWeight: 600 }} className="mt-3">
                  Status:
                </span>
                <span
                  className={`mt-3 w-50 text-md-end fw-medium text-start ${
                    item.status === 0
                      ? "text-warning"
                      : item.status === 1
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  {item.status === 0
                    ? "Pending"
                    : item.status === 1
                    ? "Approved"
                    : "Declined"}
                </span>
              </div>
            </Card>
          )
        })}
    </div>
  )
}
const Report = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"))

  const getSubjectAccessReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "GET",
        url: "https://marcopolo.prisms.in/api/teacher-subjectAccess/getAllSubjectAccessRequests",

        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubjectAccessReport()
  }, [])

  return (
    <>
      <div className="page-content">
        <Container>
          <Card className="shadow-lg rounded-lg">
            <CardBody>
              <h1
                className="text-center mb-4 fs-3"
                style={{ color: "#3c3c3c" }}
              >
                Subject Access Report
              </h1>

              <Row className="mb-4">
                <Col xl="12" lg="12" md="12" sm="12" className="mb-4">
                  {loading ? (
                    <Spinners />
                  ) : (
                    <ResuableComponent data={data || []} />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Report
