import React, { useState, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import "./style.css"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import { Card, Container, CardBody, ListGroup, ListGroupItem } from "reactstrap"
import { replaceS3Url } from "components/Common/ReplaceS3Url"

const ImageList = () => {
  const { id, repoId } = useParams()
  const [loadings, setLoadings] = useState(false)
  const [error, setErrors] = useState({})
  const [folders, setFolders] = useState([])
  const sid = localStorage.getItem("sid").replace(/"/g, "")
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    const GetGalleryApi = async () => {
      setLoadings(true)
      try {
        await axios({
          method: "POST",
          url: "https://topschool.prisms.in/rest/index.php/staff_list.json",
          data: {
            fun_name: "getDocRepoByDetailsByFolderIDSub",
            sid: sid,
            fol_id: repoId,
            repoId: id,
          },
        }).then(res => {
          setLoadings(false)
          setFolders(res.data.result)
        })
      } catch (error) {
        console.log(error)
        setLoadings(false)
      }
    }
    GetGalleryApi()
  }, [sid, id, repoId])

  const getFileNameWithoutExtension = s3Url => {
    const fileNameWithExt = replaceS3Url(s3Url).split("/").pop()
    const fileNameWithoutExt = fileNameWithExt.replace(/\.[^/.]+$/, "")
    const baseFileName = fileNameWithoutExt.replace(/_\d+$/, "")

    return baseFileName
  }

  if (loadings) {
    return (
      <div className="page-content">
        <Container>
          <Spinners />
        </Container>
      </div>
    )
  }

  if (!folders || dataSource.folders === 0) {
    return (
      <div className="page-content">
        <Container>
          <CardBody>
            <Card>
              <h1 className="text-center mt-2 mb-4 fs-5">Not Available</h1>
            </Card>
          </CardBody>
        </Container>
      </div>
    )
  }
  return (
    <div className="page-content">
      <ListGroup>
        {folders && folders.map((folder, index) => (
          <div key={index}>
            <ListGroupItem className="w-100d-flex align-items-start justify-content-start py-4">
              <div className="d-flex align-items-start justify-content-start">
                {folder.FILE && (
                  <i className="bx bxs-file-pdf fs-1 text-danger mr-5"></i>
                )}
                {folder.FILE && (
                  <a
                    className="fw-medium fs-5 text-dark custom-left-margin"
                    style={{ cursor: "pointer" }}
                    href={replaceS3Url(folder.FILE)}
                    download
                  >
                    {getFileNameWithoutExtension(folder.FILE)}
                  </a>
                )}
              </div>
              <div className="w-100 d-flex justify-content-end ">
                <span>{folder.up_date}</span>
              </div>
            </ListGroupItem>
          </div>
        ))}
      </ListGroup>
    </div>
  )
}

export default ImageList
