import React, { useState, useEffect } from "react"
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Table,
  Button,
  Col,
  Row,
  Spinner,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import * as Yup from "yup"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import RemarksTable from "./Remark"
const CreateAssignment = () => {
  const sid = localStorage.getItem("sid").replace(/"/g, "")

  const [searchedStud, setSearchedStud] = useState([])
  const [classList, setClasslist] = useState([])
  const [divList, setDivList] = useState([])
  const [studList, setStudList] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [staffList, setStaffList] = useState([])
  const [studentReport, setStudentReport] = useState([])
  const [studentName, setStudentName] = useState()
  const [loading, setLoading] = useState(false)

  const live_Url = "https://marcopolo.prisms.in"

  const getAllDiv = async class_id => {
    // setApiLoader(true);
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/tscommon/getalldiv`,
        data: { sid: sid, class_id: class_id },
      }).then(response => {
        setDivList(response.data)
      })

      // setApiLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error)
      // setApiLoader(false);
    }
  }

  const getAllClasses = async () => {
    // setApiLoader(true);
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/tscommon/getallclass`,
        data: { sid: sid },
      }).then(response => {
        setClasslist(response.data)
      })

      // setApiLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error)
      // setApiLoader(false);
    }
  }

  const getAllStudents = async division => {
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/tscommon/getstudentdetails`,
        data: { sid: sid, division: division },
      }).then(response => {
        setStudList(response.data)
      })

      // setApiLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error)
      // setApiLoader(false);
    }
  }

  const getAllReports = async stud_id => {
    if (studList.length > 0 || searchedStud.length > 0) {
      try {
        if (!stud_id) {
          throw new Error("studend not selected")
        }
        getStudentName(stud_id)
        setLoading(true)

        await axios({
          method: "POST",
          url: `${live_Url}/api/portfolio/report`,
          data: { sid: sid, stud_id },
        }).then(response => {
          setLoading(false)

          setStudentReport(response.data.data)
        })

        // setApiLoader(false);
      } catch (error) {
        setLoading(false)

        console.error("Error fetching data:", error)
        // setApiLoader(false);
      }
    }
  }

  const getStudentName = async stud_id => {
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/tscommon/student-name`,
        data: { sid: sid, stud_id },
      }).then(response => {
        setStudentName(response.data[0].student_name)
      })

      // setApiLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error)
      // setApiLoader(false);
    }
  }

  const SearcheStudentApi = async payload => {
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/idcard/searchby/name`,
        data: { sid: sid, payload },
      }).then(response => {
        console.log(response.data)
        setSearchedStud(response.data)
      })

      // setApiLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error)
      // setApiLoader(false);
    }
  }
  useEffect(() => {
    if (searchQuery !== "") {
      SearcheStudentApi(searchQuery)
    } else {
      //  setSearchedStud([]);
      // dispatch(searchStudentReset());
    }
  }, [searchQuery])

  useEffect(() => {
    getAllClasses()
  }, [])

  return (
    <div className="page-content">
      <Container>
        <Card className="shadow-lg rounded-lg">
          <CardBody>
          <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Portfolio Report
            </h1>
            <Row className="mb-4">
              <Col xl="12" md="12" sm="12" className="mt-2">
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Search Student Name"
                    onChange={e => setSearchQuery(e.target.value)}
                    value={searchQuery}
                  />
                  {searchQuery.length > 0 && searchedStud.length > 0 ? (
                    <div style={{ width: "100%" }}>
                      <ListGroup
                        style={{
                          maxHeight: "100px",
                          overflowY: "scroll",
                          backgroundColor: "white",
                          fontFamily: "Poppins",
                          zIndex: 1,
                          border: "1px solid gray",
                          padding: "0",
                          fontWeight: 500,
                        }}
                      >
                        {searchedStud.map((stud, index) => (
                          <ListGroupItem
                            key={index}
                            style={{
                              cursor: "pointer",
                              fontFamily: "Poppins",
                              transition: "background-color 0.3s, color 0.3s",
                            }}
                            // onMouseEnter={e =>
                            //   (e.target.style.backgroundColor = "blue")
                            // }
                            // onMouseLeave={e =>
                            //   (e.target.style.backgroundColor = "white")
                            // }
                            onClick={() => {
                              setSearchQuery(`${stud.fname} ${stud.lname}`)
                              getAllReports(stud.user_id)
                              // dispatch(searchStudentReset())
                            }}
                          >
                            {stud.fname} {stud.lname} - {stud.div_name}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </Col>

              <hr />

              <Col xl="4" className="mt-2">
                <FormGroup>
                  <select
                    id="Selectteachers"
                    name="classdivid"
                    className="form-control"
                    onChange={e => getAllDiv(e.target.value)}
                  >
                    <option value="">Select Class</option>
                    {classList.length > 0 &&
                      classList.map((myClass, index) => (
                        <option value={myClass.id} key={index}>
                          {myClass.class_name}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>

              <Col xl="4" className="mt-1">
                <FormGroup>
                  <select
                    id="Selectteachers"
                    name="classdivid"
                    className="form-control"
                    onChange={e => getAllStudents(e.target.value)}
                  >
                    <option value="">Select Divisions</option>
                    {divList.length > 0 &&
                      divList.map((divs, index) => (
                        <option value={divs.id} key={index}>
                          {divs.div_name}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>

              <Col xl="4" className="mt-1">
                <FormGroup>
                  <select
                    id="Selectteachers"
                    name="classdivid"
                    className="form-control"
                    onChange={e => getAllReports(e.target.value)}
                  >
                    <option value="">Select Student</option>
                    {studList.map(stud => (
                      <option value={stud.user_id} key={stud.user_id}>
                        {stud.fname} {stud.lname}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>

              <RemarksTable
                data={studentReport || []}
                studentName={studentName}
                loading={loading}
              />
            </Row>
          </CardBody>
        </Card>
      </Container>
      <ToastContainer />
    </div>
  )
}

export default CreateAssignment
