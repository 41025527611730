import React, { useEffect } from "react"
import { Container, Row, Col, Card } from "reactstrap"
import { Link } from "react-router-dom"

const Home = () => {
  const token = localStorage.getItem("Token")?.replace(/"/g, "")
  const handleClick = () => {
    window.location.href = `https://pmapp.prisms.in/visitor-scanner?t=${token}`
    // window.location.href = `http://localhost:3000/visitor-scanner?t=${token}`
    
  }

  return (
    <div
      className="page-content"
      style={{ backgroundColor: "#f0f4f8", }}
    >
      <Container
        className="d-flex justify-content-center"
        style={{ }}
      >
        <Card
          className="shadow-lg p-4"
          style={{
            maxWidth: "450px",
            borderRadius: "16px",
            background: "linear-gradient(135deg, #ffffff, #f8f9fc)",
          }}
        >
          <h2
            className="text-center mb-4"
            style={{ color: "#333", fontWeight: "600" }}
          >
            Visitor
          </h2>
          <Row className="text-center">
            <Col xs="12" className="mb-4">
              <div className="icon-container mb-3">
                <i
                  className="bx bx-money"
                  style={{ fontSize: "40px", color: "#007bff" }}
                />
              </div>
              <button
                className="btn btn-primary w-50 py-3 shadow-sm"
                onClick={handleClick}
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  borderRadius: "10px",
                  background: "linear-gradient(135deg, #007bff, #0056b3)",
                  border: "none",
                  color: "#fff",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                }}
                onMouseEnter={e => {
                  e.target.style.transform = "scale(1.05)"
                  e.target.style.boxShadow = "0px 6px 18px rgba(0, 0, 0, 0.2)"
                }}
                onMouseLeave={e => {
                  e.target.style.transform = "scale(1)"
                  e.target.style.boxShadow = "none"
                }}
              >
                Scan QR Code
              </button>
            </Col>

            {/* Enter Manually */}
            <Col xs="12" className="mb-4">
              <div className="icon-container mb-3">
                <i
                  className="bx bx-id-card"
                  style={{ fontSize: "40px", color: "#28a745" }}
                />
              </div>
              <Link to="/visitor-manually" className="w-100">
                <button
                  className="btn btn-success w-50 py-3 shadow-sm"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    borderRadius: "10px",
                    background: "linear-gradient(135deg, #28a745, #1e7e34)",
                    border: "none",
                    color: "#fff",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                  onMouseEnter={e => {
                    e.target.style.transform = "scale(1.05)"
                    e.target.style.boxShadow = "0px 6px 18px rgba(0, 0, 0, 0.2)"
                  }}
                  onMouseLeave={e => {
                    e.target.style.transform = "scale(1)"
                    e.target.style.boxShadow = "none"
                  }}
                >
                  Enter Manually
                </button>
              </Link>
            </Col>

            {/* Checkout */}
            <Col xs="12" className="mb-4">
              <div className="icon-container mb-3">
                <i
                  className="bx bx-log-out"
                  style={{ fontSize: "40px", color: "#dc3545" }}
                />
              </div>
              <Link to="/visitors" className="w-100">
                <button
                  className="btn btn-danger w-50 py-3 shadow-sm"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    borderRadius: "10px",
                    background: "linear-gradient(135deg, #dc3545, #bd2130)",
                    border: "none",
                    color: "#fff",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                  onMouseEnter={e => {
                    e.target.style.transform = "scale(1.05)"
                    e.target.style.boxShadow = "0px 6px 18px rgba(0, 0, 0, 0.2)"
                  }}
                  onMouseLeave={e => {
                    e.target.style.transform = "scale(1)"
                    e.target.style.boxShadow = "none"
                  }}
                >
                  Checkout
                </button>
              </Link>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default Home
