import React from 'react';

const Message = ({ text }) => {
    let finalText = '';
    const words = text.split(/\s+/); 

    for (const word of words) {
        const moduleRegex = new RegExp(/https?:\/\/[^\s]+/g); 
        const isExternalLink = moduleRegex.test(word);
        if (isExternalLink) {
            finalText += ` <a href="${word}" target="_blank" rel="noopener noreferrer">${word}</a>`;
        } else {
            finalText += ` ${word}`;
        }
    }

    return <p  style={{ whiteSpace: 'pre-line', fontWeight:'400', letterSpacing:'0.7', color:'#111' }} dangerouslySetInnerHTML={{ __html: finalText.trim() }} />;
};

export default Message;
