import React, { useState } from "react"
import { Container, Card, Alert } from "reactstrap"

const StudentAssignment = () => {
  const [data, setData] = useState([])

  return (
    <div className="page-content">
      {data.length === 0 ? (
        <Alert className="text-center mt-4">Assignments Not Submitted</Alert>
      ) : (
        data.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#fff",
              borderRadius: "4px",
              marginTop: "5px",
              padding: "10px",
              position: "relative",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="w-80">
                <div className="d-flex flex-column">
                  <span className="mt-3 text-start">{item.assignmentName}</span>
                  <span className="mt-1 text-start">
                    Description: {item.description}
                  </span>
                </div>
              </div>
              <div className="w-30 mt-4 text-center">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  {/* Download Assignment */}
                  <span className="mt-3 mx-1">
                    <a
                      href={item.file_location}
                      download
                      title="Download Assignment"
                    >
                      <i
                        className="bx bx-archive-in"
                        style={{ fontSize: "18px" }}
                      />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default StudentAssignment
