import React, { useEffect, useState, useRef } from "react"
import QrScanner from "qr-scanner"
import { useLocation, useNavigate } from "react-router-dom"
import axios from "axios"

import { Container, Card, CardBody, Row, Col } from "reactstrap"

const NewScanner = () => {
  const navigate = useNavigate()
  let baseUrl = "https://marcopolo.prisms.in/api/visitor/QR-scan-visitor"

  const location = useLocation()
  const videoRef = useRef(null)
  const [scanMessage, setScanMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [scanResult, setScanResult] = useState(null)

  function fixAndParseJSON(rawData) {
    let fixedData = rawData
      .replace(/(\w+):/g, '"$1":')
      .replace(/:\s*([^",\}\]]+)/g, function (match, value) {
        if (/^\d+(\.\d+)?$/.test(value)) {
          return `:${value}`
        } else if (value === "true" || value === "false" || value === "null") {
          return `:${value}`
        } else {
          return `:"${value.replace(/"/g, '\\"')}"`
        }
      })

    try {
      let parsedData = JSON.parse(fixedData)
      return parsedData
    } catch (error) {
      console.error("Error parsing JSON:", error)
      return null
    }
  }

  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search)
    return params.get("t")
  }

  const token = getTokenFromUrl()

  useEffect(() => {
    let scanner

    const initializeScanner = () => {
      try {
        setIsLoading(true)

        if (videoRef.current) {
          scanner = new QrScanner(videoRef.current, async result => {
            setScanMessage("")
            const data = fixAndParseJSON(result)

            if (data) {
              console.log(data[0]["userid"])
              try {
                if (data && data[0]["userid"]) {
                  setScanResult({
                    // encryptedUserId: await encryption(data[0]["userid"]),
                    encryptedUserId: data[0]["userid"],
                    visit_id: `${data[0]["visit_id"]}`,
                    type: data[0]["type"],
                    date: data[0]["Date"],
                    visit_purpose: data[0]["Visit_purpose"],
                  })

                  // Open the modal with the scanned result
                  setModalOpen(true)
                } else {
                  setErrorMessage("Invalid QR code data")
                }
              } catch (error) {
                setErrorMessage("Error parsing QR result.")
              }
            } else {
              setErrorMessage("QR result is not valid.")
            }
          })

          scanner
            .start()
            .then(() => {
              console.log("QR scanner started successfully.")
              setIsLoading(false)
            })
            .catch(err => {
              console.error("Error starting the scanner", err)
              setErrorMessage("Failed to start QR scanner.")
              setIsLoading(false)
            })
        } else {
          setErrorMessage("Video ref is not available")
          setIsLoading(false)
        }
      } catch (error) {
        console.error("Error initializing scanner:", error)
        setErrorMessage("Failed to load QR scanner")
        setIsLoading(false)
      }
    }

    initializeScanner()

    return () => {
      if (scanner) {
        scanner.stop()
        console.log("QR scanner stopped.")
      }
    }
  }, [token])

  const handleFormSubmit = async e => {
    // e.preventDefault()

    if (!scanResult || scanResult.length === 0) {
      console.error("No data available in scanResult")
      return
    }

    try {
      await axios({
        method: "POST",
        url: baseUrl,
        data: scanResult,
        headers: {
          Authorization: token,
        },
      }).then(response => {
        if (response.data.message || response.data.data) {
          navigate('/scan', { state: response.data.data });

        }
      })
    } catch (error) {
      toast.error("Something went wrong")
      setModalOpen(false)
      setErrorMessage("Something went wrong")
    }
  }

  useEffect(() => {
    if (scanResult) {
      handleFormSubmit();
    }
  }, [scanResult]);
  

  return (
    <div className="page-content">
      <Container>
        <Row className="r">
          <Col xs={12} sm={8} md={6} lg={5}>
            <Card className="shadow p-4 mt-4">
              <CardBody>
                <h1
                  className="text-center mt-2 mb-4 fs-2"
                  style={{ color: "#3c3c3c", fontWeight: "600" }}
                >
                  Scan QR
                </h1>
                <video
                  ref={videoRef}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  playsInline
                />
                {isLoading && <p className="text-center fw-bold">Loading...</p>}
                {scanMessage && <p>{scanMessage}</p>}
                {errorMessage && (
                  <p className="text-center fw-bold" style={{ color: "red" }}>
                    {errorMessage}
                  </p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewScanner
