import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"
import { useParams, useLocation } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap"

const ResuableComponent = ({ data }) => {
  return (
    <div>
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                marginTop: "5px",
                padding: "10px",
                position: "relative",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="w-100">
                  <span className="mt-3 fs-5 text-start fw-medium">
                    Recipient No -{" "}
                  </span>

                  <span className="mt-3 fs-5 text-start text-dark">
                    {item.rcp_no}
                  </span>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <span className="mt-3 fs-5 text-start">
                      {item.category}
                    </span>
                    <span className="mt-1 fs-5 text-dark fw-medium text-start">
                      ₹ {item.amount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "4px",
          marginTop: "5px",
          padding: "10px",
          position: "relative",
          color: "black",
          backgroundColor: "#efedff",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span className="mt-3 fs-5 text-start">Payment Mode</span>
          <span className="mt-1 fs-5 text-dark fw-medium text-dark">
            {data && data[0] && data[0].payment_mode
              ? data[0].payment_mode
              : null}
          </span>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "4px",
          marginTop: "5px",
          padding: "10px",
          backgroundColor: "#efedff",
          color: "black",
          position: "relative",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span className="mt-3 fs-5 text-start">Total Amount Paid</span>
          <span className="mt-1 fs-5 text-dark fw-medium text-dark">
            ₹ {data && data[0] && data[0].total_paid_fees}
          </span>
        </div>
      </div>
    </div>
  )
}
const Report = () => {
  const { user_id, id } = useParams()
  const [data, setData] = useState(null)
  const [studentName, setStudentName] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"));
  const location = useLocation();


  const getFeesHistory = async () => {
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/FeesReport/get-paid-fees-history",
        data: {
          student_id: user_id,
          package_id: id,
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data.data.data)
        setStudentName(res.data.data.student_name)
        setLoading(false)
      })
    } catch (error) {
      console.error(error.message)
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getFeesHistory()
  }, [])

  if (error) {
    return (
      <div className="page-content">
        <h1 className="text-center text-danger mt-2 mb-4 fs-4">
          Oops something went wrong!
        </h1>
      </div>
    )
  }
  return (
    <>
      <div className="page-content">
        <Container className="">
          <Card className="shadow-lg rounded-lg">
            <CardBody>
              <h1
                className="text-center mb-4 fs-4"
                style={{ color: "#333" }}
              >
                {studentName}
              </h1>

              <Row className="mb-4">
                <Col xl="12" md="12" sm="12">
                  {loading ? (
                    <Spinners />
                  ) : (
                    <ResuableComponent data={data || []} />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}
//        {loading ? <Spinners /> : <ResuableComponent data={data || []} />}

export default Report
