export const GET_ATTENDANCE_DIV = "GET_ATTENDANCE_DIV"
export const GET_ATTENDANCE_DIV_SUCCESS = "GET_ATTENDANCE_DIV_SUCCESS"
export const GET_ATTENDANCE_DIV_FAILED = "GET_ATTENDANCE_DIV_FAILED"

export const GET_STUDLIST = "GET_STUDLIST"
export const GET_STUDLIST_SUCCESS = "GET_STUDLIST_SUCCESS"
export const GET_STUDLIST_FAIL = "GET_STUDLIST_FAIL"


export const MARK_ABSENT_STUD = "MARK_ABSENT_STUD"
export const MARK_ABSENT_STUD_SUCCESS = "MARK_ABSENT_STUD_SUCCESS"
export const MARK_ABSENT_STUD_FAILED = "MARK_ABSENT_STUD_FAILED"


export const GET_ATTEND_REPORT = "GET_ATTEND_REPORT"
export const GET_ATTEND_REPORT_SUCCESS = "GET_ATTEND_REPORT_SUCCESS"
export const GET_ATTEND_REPORT_FAILED = "GET_ATTEND_REPORT_FAILED"
export const GET_ATTEND_REPORT_RESET = "GET_ATTEND_REPORT_RESET"