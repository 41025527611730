import React from "react"
import { Card, Button, Container, Row, Col } from "reactstrap" // Import Button from reactstrap
import { Link } from "react-router-dom"

const LeaveApp = () => {
  return (
    <div className="page-content" style={{ backgroundColor: "#f4f7fc", minHeight: "100vh" }}>
      <Container className="py-5">
        <Card
          className="shadow-lg p-4"
          style={{
            borderRadius: "16px",
            backgroundColor: "#ffffff",
            maxWidth: "600px",
            margin: "auto",
          }}
        >
          <h1 className="text-center mt-2 mb-4 fs-2" style={{ color: "#3c3c3c", fontWeight: "600" }}>
            Apply Leave
          </h1>

          <Row className="d-flex justify-content-center flex-column align-items-center justify-content-center">
            <Col xs="auto" className="mt-4 mb-3">
              <Link to={"/student/apply-leave"}>
                <Button
                  color="primary"
                  className="px-4 py-2"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease-in-out",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.boxShadow = "0px 6px 18px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)";
                  }}
                >
                  <i className="fa fa-paper-plane mr-2"></i> Apply Leave
                </Button>
              </Link>
            </Col>

            <Col xs="auto" className="mt-3 mb-5">
              <Link to={"/student/Leave-report"}>
                <Button
                  color="dark"
                  className="px-4 py-2"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease-in-out",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.boxShadow = "0px 6px 18px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)";
                  }}
                >
                  <i className="fa fa-eye mr-2"></i> Leave Report
                </Button>
              </Link>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default LeaveApp
