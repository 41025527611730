import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import "./style.css"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import { replaceS3Url } from "components/Common/ReplaceS3Url"

const ImageList = () => {
  const { id } = useParams()
  const [images, setImages] = useState([])
  const [gdata, setGdata] = useState(false)
  const [loadings, setLoadings] = useState(false)
  const [error, setErrors] = useState({})
  const sid = localStorage.getItem("sid").replace(/"/g, "")

  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const handleImageClick = (image, index) => {
    console.log("Selected image:", image)
    setSelectedImage(image)
    setSelectedIndex(index)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedImage(null)
    setSelectedIndex(null)
  }

  const handleNextImage = () => {
    if (selectedIndex < images.length - 1) {
      setSelectedImage(images[selectedIndex + 1])
      setSelectedIndex(selectedIndex + 1)
    }
  }

  const handlePrevImage = () => {
    if (selectedIndex > 0) {
      setSelectedImage(images[selectedIndex - 1])
      setSelectedIndex(selectedIndex - 1)
    }
  }

  const handleDownload = () => {
    if (selectedImage && selectedImage.snap) {
      const link = document.createElement("a")
      link.href = replaceS3Url(selectedImage.snap)
      link.download = selectedImage.title || "download"
      link.click()
    }
  }

  useEffect(() => {
    const GetGalleryApi = async () => {
      setGdata(true)
      setLoadings(true)
      try {
        await axios({
          method: "POST",
          url: "https://topschool.prisms.in/rest/index.php/staff_list.json?",
          data: {
            fun_name: "GetGalleryImages",
            sid: sid,
            Albumid: id,
          },
        }).then(res => {
          setGdata(false)
          setLoadings(false)

          console.log("Gallery data:", res.data)
          setImages(res.data.result)
        })
      } catch (error) {
        setGdata(false)
        setLoadings(false)

        setErrors(error)
        console.log("Error fetching gallery data:", error)
      }
    }
    GetGalleryApi()
  }, [sid, id])

  return (
    <div className="page-content">
      {loadings ? (
        <Spinners />
      ) : images.length > 0 ? (
        <div className="image-grid">
          {images.map((image, index) => (
            <div className="image-item" key={index}>
              <img
                src={replaceS3Url(image.snap)}
                alt={image.title}
                className="image"
                onClick={() => handleImageClick(image, index)}
              />
              {/* <span>{image.added_by}</span> */}
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex">
          <Card>
            <p className="text-center fs-4">
              Images are not found in this album
            </p>
          </Card>
        </div>
      )}

      <Modal isOpen={modalOpen} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          {selectedImage && selectedImage.title}
        </ModalHeader>
        <ModalBody>
          {selectedImage ? (
            <div className="modal-image-container">
              <img
                src={replaceS3Url(selectedImage.snap)}
                alt={selectedImage.title}
                className="modal-image"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          ) : (
            <p>Loading image...</p>
          )}
        </ModalBody>
        <ModalFooter>
          {/* <Button color="secondary" onClick={handleCloseModal}>
            Close
          </Button> */}
          <Button
            color="primary"
            onClick={handlePrevImage}
            disabled={selectedIndex === 0}
          >
            Previous
          </Button>
          <Button
            color="primary"
            onClick={handleNextImage}
            disabled={selectedIndex === images.length - 1}
          >
            Next
          </Button>
          <Button color="success" onClick={handleDownload}>
            Download
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ImageList
