import React, { useState, useEffect } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Alert,
} from "reactstrap"
import axios from "axios"
import Spinners from "components/Common/Spinner"

function Example({ data }) {
  const [open, setOpen] = useState("1")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const NewData = data.data

  return (
    <div>
      <Accordion open={open} toggle={toggle}>
        {NewData && NewData.length > 0 ? (
          NewData.map((item, index) => (
            <AccordionItem key={index}>
              <AccordionHeader targetId={`${item.id}`}>
                {item.subject}
              </AccordionHeader>
              <AccordionBody accordionId={`${item.id}`}>
                <div className="d-flex justify-content-between align-items-start">
                  <span className="w-50 fw-bold text-dark">Topic Name</span>
                  <span className="w-50">{item.topic_name}</span>
                </div>
                <div className="d-flex justify-content-between align-items-start mt-3">
                  <span className="w-50 fw-bold text-dark">Description</span>
                  <span className="w-50">{item.description}</span>
                </div>

                {item.attachments && item.attachments.length > 0 && (
                  <div className="d-flex justify-content-between flex-column align-items-start mt-3">
                    <span className="w-100 fw-bold text-dark">Attachments</span>
                    <div className="mt-3 w-100">
                      {item.attachments && item.attachments.length > 0 ? (
                        item.attachments.map((attachment, idx) => (
                          <div
                            key={idx}
                            className="d-flex align-items-center mt-3"
                            style={{
                              border: "1px solid #ddd",
                              borderRadius: "8px",
                              padding: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            {attachment && (
                              <>
                                <div
                                  className="me-3"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "20px",
                                    color: "#007bff",
                                    cursor: "pointer",
                                    transition: "color 0.2s",
                                  }}
                                  onMouseOver={e =>
                                    (e.target.style.color = "#0056b3")
                                  }
                                  onMouseOut={e =>
                                    (e.target.style.color = "#007bff")
                                  }
                                  onClick={() => {
                                    const link = document.createElement("a")
                                    link.href = attachment
                                    link.download = attachment.split("/").pop()
                                    document.body.appendChild(link)
                                    link.click()
                                    document.body.removeChild(link)
                                  }}
                                >
                                  <i className="bx bx-download"></i>
                                </div>

                                <div
                                  className="text-dark bg-light p-2"
                                  style={{
                                    fontSize: "14px",
                                    maxWidth: "220px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    borderRadius: "4px",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                  }}
                                >
                                  {/* <i
                                    className="bx bx-download"
                                    style={{
                                      marginRight: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const link = document.createElement("a")
                                      link.href = attachment
                                      link.download = attachment
                                        .split("/")
                                        .pop()
                                      document.body.appendChild(link)
                                      link.click()
                                      document.body.removeChild(link)
                                    }}
                                  ></i> */}
                                  <a
                                    className="attachments"
                                    style={{
                                      textDecoration: "none",
                                      color: "#007bff",
                                      transition: "color 0.2s",
                                    }}
                                    href={attachment}
                                    download 
                                    onMouseOver={e =>
                                      (e.target.style.color = "#0056b3")
                                    }
                                    onMouseOut={e =>
                                      (e.target.style.color = "#007bff")
                                    }
                                  >
                                    {attachment
                                      .split("/")
                                      .pop()
                                      .split(".")
                                      .slice(0, -1)
                                      .join(".")}
                                  </a>
                                </div>
                              </>
                            )}
                          </div>
                        ))
                      ) : (
                        <p className="text-muted">No attachments available</p>
                      )}
                    </div>
                  </div>
                )}
              </AccordionBody>
            </AccordionItem>
          ))
        ) : (
          <div className="">
            {" "}
            <Alert className="text-center">Notes Not Available</Alert>
          </div>
        )}
      </Accordion>
    </div>
  )
}

const SubmitLog = () => {
  let live_Url = "https://marcopolo.prisms.in"

  const sid = localStorage.getItem("sid")
  const uid = localStorage.getItem("user_id")
  const [apiLoader, setApiLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [CData, setCData] = useState()
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  useEffect(() => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1
    const currentDay = currentDate.getDate()

    setEndDate(
      `${currentYear}-${String(currentMonth).padStart(2, "0")}-${String(
        currentDay
      ).padStart(2, "0")}`
    )

    const lastWeekDate = new Date(currentDate)
    lastWeekDate.setDate(currentDay - 7)

    const lastWeekYear = lastWeekDate.getFullYear()
    const lastWeekMonth = lastWeekDate.getMonth() + 1
    const lastWeekDay = lastWeekDate.getDate()

    setStartDate(
      `${lastWeekYear}-${String(lastWeekMonth).padStart(2, "0")}-${String(
        lastWeekDay
      ).padStart(2, "0")}`
    )
  }, [])

  useEffect(() => {
    const GetNotes = async () => {
      setApiLoader(true)
      setTimeout(async () => {
        try {
          const response = await axios({
            method: "POST",
            url: `${live_Url}/api/notification/notes`,
            data: {
              sid: sid,
              uid: uid,
              startDate: startDate,
              endDate: endDate,
            },
          })
          console.log(response.data.data)
          setCData(response.data.data)
        } catch (error) {
          console.error("API request error:", error)
        } finally {
          setApiLoader(false)
        }
      }, 1000)
    }

    GetNotes()
  }, [sid, uid, startDate, endDate])

  const onSearch = e => {
    const searchQuery = e.target.value.trim().toLowerCase()
    setLoading(true)

    setTimeout(() => {
      const filteredData = CData.data.filter(item => {
        const matchesSearchQuery =
          item.topic_name && item.topic_name.toLowerCase().includes(searchQuery)
        return matchesSearchQuery
      })

      setLoading(false)
      setCData({ data: filteredData })
    }, 1000)
  }
  return (
    <div className="page-content">
      <Container className="py-4">
        <Card className="shadow-lg rounded-lg">
          <CardBody>
            <h1
              className="text-center mt-2 mb-4 fs-3"
              style={{ color: "#3c3c3c" }}
            >
              Notes
            </h1>

            <Row className="mb-4">
              <Col xl="12" md="12" sm="12">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="w-25 fw-medium fs-5">From</span>
                  <Input
                    className="e-75"
                    type="date"
                    placeholder="Select Date"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <span className="w-25 fw-medium fs-5">To</span>
                  <Input
                    className="e-75"
                    type="date"
                    placeholder="Select Date"
                    onChange={e => setEndDate(e.target.value)}
                    value={endDate}
                  />
                </div>

                <div>
                  <Input
                    type="text"
                    className="mt-3 mb-3"
                    placeholder="Quick search topic"
                    onChange={onSearch}
                  />
                </div>
                {loading ? (
                  <span className="text-dark mt-3 text-center fw-normal">
                    searching...
                  </span>
                ) : null}

                {apiLoader ? <Spinners /> : <Example data={CData || []} />}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default SubmitLog
