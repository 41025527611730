import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import { Link } from "react-router-dom"
import { replaceS3Url } from "components/Common/ReplaceS3Url"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"

const ResuableComponent = ({ data, setDeleted }) => {
  const token = JSON.parse(localStorage.getItem("Token"))
  const [modalOpen, setModalOpen] = useState(false)
  const [assignmentId, setAssignmentId] = useState(null)
  const toggleModal = () => setModalOpen(!modalOpen)

  const deleteAssignment = async () => {
    setDeleted(false)
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/Assignment/delete-assignment",
        data: {
          assignment_id: assignmentId,
        },
        headers: {
          Authorization: token,
        },
      })
      setModalOpen(false)
      setDeleted(true)
    } catch (error) {
      console.log(error)
      setDeleted(false)
    }
  }

  const handleDeleteClick = id => {
    setAssignmentId(id)
    toggleModal()
  }
  return (
    <div className="">
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                marginTop: "5px",
                padding: "10px",
                position: "relative",
              }}
            >
              <div className="d-flex justify-content-between align-items-center p-3 border rounded shadow-sm">
                <div className="w-100">
                  <div className="d-flex flex-column">
                    <span className="text-start text-muted mb-2">
                      <strong className="text-dark">Status:</strong>{" "}
                      <span
                        className={`badge ${
                          item.status_is_publish === 1
                            ? "bg-success"
                            : "bg-danger"
                        }`}
                      >
                        {item.status_is_publish === 1
                          ? "Published"
                          : "Not Published"}
                      </span>
                    </span>
                    <span className="text-start text-dark fw-bold mb-1">
                      {item.assignmentName}
                    </span>
                    <span className="text-start text-secondary">
                      Description: {item.description}
                    </span>
                  </div>
                  <div className="w-100">
                    <div
                      className="d-flex flex-row align-items-center justify-content-end gap-2"
                      // style={{
                      //   position: "absolute",
                      //   right: "23px",
                      //   top: "23px",
                      // }}
                    >
                      {/* Download Icon */}
                      <a
                        href={replaceS3Url(item.file_location)}
                        download
                        title="Download Assignment"
                        className="btn btn-primary btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i
                          className="bx bx-download"
                          style={{ fontSize: "12px" }}
                        />
                      </a>

                      {/* Delete Icon */}
                      <button
                        onClick={() => handleDeleteClick(item.id)}
                        className="btn btn-danger btn-sm d-flex align-items-center justify-content-center"
                        title="Delete Assignment"
                      >
                        <i
                          className="bx bxs-trash"
                          style={{ fontSize: "12px" }}
                        />
                      </button>

                      {/* View Icon */}
                      <Link
                        to={`/view-assignment/${item.id}`}
                        className="btn btn-outline-dark btn-sm d-flex align-items-center justify-content-center"
                        title="View Assignment"
                      >
                        <i className="fa fa-eye" style={{ fontSize: "12px" }} />
                      </Link>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          )
        })}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to delete this assignment?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            No
          </Button>
          <Button color="danger" onClick={deleteAssignment}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const Report = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"))
  const [isDeleted, setDeleted] = useState(false)

  const getSubjectAccessReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "GET",
        url: "https://marcopolo.prisms.in/api/Assignment/get-all-assignments",

        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubjectAccessReport()
  }, [isDeleted])

  return (
    <>
      <div className="page-content">
        <Container>
          <Card className="shadow-lg rounded-lg">
            <CardBody>
              <h1
                className="text-center mb-4 fs-3"
                style={{ color: "#3c3c3c" }}
              >
                Assignment
              </h1>
              <Row className="mb-4">
                <Col xl="12" md="12" sm="12">
                  {loading ? (
                    <Spinners />
                  ) : (
                    <ResuableComponent
                      data={data || []}
                      setDeleted={setDeleted}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Report

{
  /* <h1 className="text-center mt-2 mb-4 fs-4">Assignment Report</h1>

{loading ? (
  <Spinners />
) : (
  <ResuableComponent data={data || []} setDeleted={setDeleted} />
)} */
}
