import moment from "moment"
import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { Button, Spinner, Row, Table, Col } from "reactstrap"
import { printPaidFeesDeatails } from "store/actions"

const PrintRecipt = () => {
  const captureRef = useRef()


  const location = useLocation()
  const dispatch = useDispatch()
  const { schoolInfo, Loading } = useSelector(state => state.SchoolInfoReducer)
  const { printRecipt, Loading: feesLoading } = useSelector(
    state => state.FeesReducer
  )
  const { printId, acadInfo } = location.state

  const calTotalFees = array => {
    // console.log(array)
    let totalFees = 0
    if (array && Array.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        totalFees += array[i].amount
      }
    }

    return totalFees
  }

  useEffect(() => {
    dispatch(printPaidFeesDeatails({ printId: printId }))
  }, [dispatch])

  // const downloadPdf = () => {
  //   const token = localStorage.getItem("token")
  //   const queryString = new URLSearchParams(printId).toString()

  //   window.open(`http://192.168.1.8:3000/feesdetails?${queryString}`,"_blank")
  //   // html2canvas(captureRef.current, {
  //   //   scale: 3,
  //   //   useCORS: true,
  //   // }).then(canvas => {
  //   //   const imageData = canvas.toDataURL("image/png")

  //   //   const pdf = new jsPDF()
  //   //   const imgWidth = 180
  //   //   const imgHeight = (canvas.height * imgWidth) / canvas.width
  //   //   pdf.addImage(imageData, "PNG", 10, 10, imgWidth, imgHeight)
  //   //   pdf.save("feesReceipt.pdf")
  //   // })
  // }

  const downloadPdf = () => {
    const token = localStorage.getItem("Token");
    const encodedToken = btoa(token);
    const encodedPrintId = btoa(printId);
    const ecodeS = btoa(schoolInfo);
    const ecodeA = btoa(acadInfo.academics);
    const queryString = new URLSearchParams({
        n: encodedToken,
        p: encodedPrintId,
        s:ecodeS,
        a:ecodeA
    }).toString();

    window.open(`https://pmapp.prisms.in/feesdetails?${queryString}`, "_blank");
}

  const ResuableComponent = ({ title, data }) => {
    return (
      <Col
        className="d-flex justify-content-between align-items-center fw-medium"
        style={{
          margin: "0 12px",
        }}
      >
        <div className="w-50">
          <span className="fs-6 mt-1">{title}</span>
        </div>
        <div className="w-50">
          <span className="fs-6 mt-1">{data}</span>
        </div>
      </Col>
    )
  }
  return (
    <div className="page-content h-100 px-1 bg-white">
      <div ref={captureRef}>
        <div className="LogoContainer">
          <img
            src={schoolInfo.school_logo}
            alt={schoolInfo.name}
            className="schoolLogo"
          />
          <span
            className="d-block text-dark"
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: schoolInfo.name }}
          />
        </div>
        <div className="my-3">
          <Row>
            <div>
              <ResuableComponent
                title={"Student Name:"}
                data={
                  printRecipt &&
                  Array.isArray(printRecipt?.data) &&
                  printRecipt?.data[0]?.name
                }
              />
              <ResuableComponent
                title={"Acadmic Year:"}
                data={acadInfo?.academics && acadInfo?.academics}
              />
              <ResuableComponent
                title={"Recipt No:"}
                data={
                  printRecipt &&
                  Array.isArray(printRecipt?.data) &&
                  printRecipt?.data[0]?.rcp_no
                }
              />
            </div>
          </Row>
        </div>
        {feesLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="">
              <Table className="">
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Category</th>
                    <th>date</th>
                    <th>amount</th>
                  </tr>
                </thead>
                <tbody>
                  {printRecipt &&
                    printRecipt.data?.map((data, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td>{data.category}</td>
                        <td>{moment(data.date).format("DD-MM-YYYY")}</td>
                        <td>{data.amount}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={2}>Total</th>
                    <th>{calTotalFees(printRecipt?.data)}</th>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        )}
      </div>
      <div className="text-center no-printme">
        <Button onClick={downloadPdf}>Download</Button>
      </div>
    </div>
  )
}

export default PrintRecipt
