import React from "react"
import { Card, Button, Container, Row, Col } from "reactstrap" // Import Button from reactstrap
import { Link } from "react-router-dom"

const SubjectHome = ({ title, r1, r2, r3, t1, t2, t3 }) => {
  const buttonStyle = {
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
    width: "250px",
  }

  const handleMouseEnter = (e) => {
    e.target.style.transform = "scale(1.05)"
    e.target.style.boxShadow = "0px 6px 18px rgba(0, 0, 0, 0.2)"
  }

  const handleMouseLeave = (e) => {
    e.target.style.transform = "scale(1)"
    e.target.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)"
  }

  return (
    <div className="page-content" style={{ backgroundColor: "#f4f7fc", minHeight: "100vh" }}>
      <Container className="py-5">
        <Card
          className="shadow-sm p-4"
          style={{
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            maxWidth: "800px",
            height:"100%",
            margin: "auto",
          }}
        >
          <h1 className="text-center mt-2 mb-4 fs-2" style={{ color: "#3c3c3c", fontWeight: "600" }}>
            {title}
          </h1>

          <Row className="d-flex justify-content-center flex-column align-items-center">
            <Col xs="auto" className="mt-4 mb-3">
              <Link to={r1}>
                <Button
                  color="primary"
                  className="px-4 py-2"
                  style={buttonStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {t1}
                </Button>
              </Link>
            </Col>

            <Col xs="auto" className="mt-3 mb-3">
              <Link to={r2}>
                <Button
                  color="dark"
                  className="px-4 py-2"
                  style={buttonStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                 {t2}
                </Button>
              </Link>
            </Col>

      { r3 && t3 &&      <Col xs="auto" className="mt-2 mb-5">
              <Link to={r3}>
                <Button
                  color="success"
                  className="px-4 py-2"
                  style={buttonStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                 {t3}
                </Button>
              </Link>
            </Col> }
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default SubjectHome
