import React, { useState, useEffect } from "react"
import { Card, Col, CardImg, Container, Input, Row, Image } from "reactstrap"
import Spinners from "components/Common/Spinner"
import axios from "axios"
import Report from "./Report"
import { toast, ToastContainer } from "react-toastify"
const Checkout = () => {
  const [search, setSearch] = useState()
  const [currentDate, setCurrentDate] = useState("")
  const [Loading, setLoading] = useState(false)
  const [date1, setDate1] = useState("")
  const [checkout, setCheckout] = useState(false)
  const [startSec, setStartSec] = useState("")
  const [startHour, setStartHour] = useState("")
  const [startMin, setStartMin] = useState("")
  const [timePeriod, setTimePeriod] = useState("")
  const [TestData, setTestData] = useState([])

  const FilterData =
    TestData &&
    TestData.filter(item => {
      if (!search || search.trim() === "") {
        return true
      }
      return item.NAME.toLowerCase().includes(search.trim().toLowerCase())
    })

  useEffect(() => {
    const today = new Date()
    const formattedDate = today.toISOString().split("T")[0]
    setCurrentDate(formattedDate)
  }, [])

  const baseUrl = "https://marcopolo.prisms.in/api/visitor/"
  const token = localStorage.getItem("Token").replace(/"/g, "")

  useEffect(() => {
    const interval = setInterval(() => {
      const timeString = new Date().toLocaleTimeString()
      const [hour, minute, second, secondWithPeriod] = timeString.split(/:| /)
      const [period] = secondWithPeriod.split(" ")

      setStartHour(hour)
      setStartMin(minute)
      setStartSec(second)
      setTimePeriod(period)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const getUserInfo = async () => {
    if (!currentDate && !date1) return

    setLoading(true)
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}get-all-checked-in-visitors`,
        data: {
          date: date1 ? date1 : currentDate,
        },
        headers: {
          Authorization: token,
        },
      })
      // console.log(response.data)
      setTestData(response.data.data)
    } catch (error) {
      console.error("Error fetching user info:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [currentDate, date1, checkout])

  const checkOut = async visit_id => {
    try {
      setCheckout(false)
      setLoading(true)
      await axios({
        method: "POST",
        url: `${baseUrl}checkout-visitor`,
        data: {
          endHour: startHour,
          endMin: startMin,
          ampmEnd: timePeriod,
          visitorId: visit_id,
        },
        headers: {
          Authorization: token,
        },
      }).then(response => {
        toast.success("checkout successfully")
        setCheckout(true)
        setLoading(false)
      })
    } catch (error) {
      toast.error("Failed to checkout")
      setCheckout(false)
      setLoading(false)
    }
  }

  return (
    <div className="">
      <Container>
        <Card className="h-full p-2">
          <Row>
            <Col>
              <Input
                type="date"
                value={date1 || currentDate}
                onChange={e => setDate1(e.target.value)}
              />

              <Input
                type="text"
                placeholder="Search by name"
                className="mt-3"
                onChange={e => setSearch(e.target.value)}
              />
              {Loading && <Spinners />}
              <div>
                {FilterData &&
                  FilterData.length > 0 &&
                  FilterData.map((item, index) => {
                    return (
                      <div key={index}>
                        <Card className="shadow-sm p-4 rounded mt-4">
                          <div className="text-center mb-3 mt-3">
                            <CardImg
                              src={
                                item.avatar ||
                                "https://ocisa.prisms.in/images/dashboard/student.png"
                              }
                              alt="Profile"
                              className="rounded-circle"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div className="d-flex flex-row flex-md-column">
                            <Row className="">
                              <div className="d-flex flex-row ">
                                <span
                                  style={{ fontWeight: 600 }}
                                  className="mt-3 w-50"
                                >
                                  <i className="fa fa-user text-primary mr-2"></i>
                                </span>
                                <span className="mt-3 w-50">{item.NAME}</span>
                              </div>
                              <div className="d-flex flex-row ">
                                <span
                                  style={{ fontWeight: 600 }}
                                  className="mt-3 w-50"
                                >
                                  <i className="fa fa-phone text-primary mr-2"></i>
                                </span>
                                <span className="mt-3 w-50">
                                  {item.mobile_no}
                                </span>
                              </div>
                              <div className="d-flex flex-row ">
                                <span
                                  style={{ fontWeight: 600 }}
                                  className="mt-3 w-50"
                                >
                                  <i className="fa fa-calendar text-primary mr-2"></i>
                                </span>
                                <span className="mt-3 w-50">{item.vDate}</span>
                              </div>

                              <div className="d-flex flex-row ">
                                <span
                                  style={{ fontWeight: 600 }}
                                  className="mt-3 w-50 text-primary"
                                >
                                  IN
                                </span>
                                <span className="mt-3 w-50">
                                  {item.in_time.toUpperCase()}
                                </span>
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                <span className="in-label text-primary fw-bold w-30"></span>
                                <span
                                  style={{ fontSize: "11px" }}
                                  className="in-details w-50 fw-medium text-dark"
                                >
                                  ({item.checked_in_by})
                                </span>
                              </div>

                              <div className="d-flex flex-row ">
                                <span
                                  style={{ fontWeight: 600 }}
                                  className="mt-3 w-50 text-primary"
                                >
                                  OUT :
                                </span>

                                {item.out_time === "--:-- --" || null ? (
                                  <button
                                    className="btn btn-outline-danger mt-2"
                                    onClick={() => checkOut(item.visitoriId)}
                                  >
                                    Checkout
                                  </button>
                                ) : (
                                  <span className="mt-3 w-50">
                                    {item.out_time.toUpperCase()}
                                  </span>
                                )}
                              </div>

                              {item.check_out_by && (
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="in-label text-primary fw-bold w-30"></span>
                                  <span
                                    style={{ fontSize: "11px" }}
                                    className="in-details w-50 fw-medium text-dark"
                                  >
                                    ({item.check_out_by})
                                  </span>
                                </div>
                              )}
                            </Row>
                          </div>
                        </Card>
                      </div>
                    )
                  })}
              </div>
            </Col>
          </Row>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  )
}

export default Checkout
