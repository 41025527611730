import React, { useEffect, useState } from "react"
import { ListGroup, ListGroupItem } from "reactstrap"
import "./style.css"
import axios from "axios"
import { Link } from "react-router-dom"
import Spinners from "components/Common/Spinner"
import moment from "moment"
const FolderList = () => {
  const [folders, setFolders] = useState([])

  const [gdata, setGdata] = useState(false)
  const [error, setErrors] = useState({})
  const sid = localStorage.getItem("sid").replace(/"/g, "")
  const uid = localStorage.getItem("user_id")
  useEffect(() => {
    const GetGalleryApi = async () => {
      setGdata(true)
      try {
        await axios({
          method: "POST",
          url: "https://topschool.prisms.in/rest/index.php/staff_list.json",
          data: {
            fun_name: "getAlbumDetailsHybrid",
            sid: sid,
            userId: uid,
          },
        }).then(res => {
          setGdata(false)

          setFolders(res.data.result)
        })
      } catch (error) {
        setGdata(false)
      }
    }
    GetGalleryApi()
  }, [])

  if (error) {
  }

  return (
    <div className="page-content">
      {gdata ? (
        <Spinners />
      ) : (
        <ListGroup>
          {folders &&
            folders.length > 0 &&
            folders.map((folder, index) => (
              <div key={index}>
                <ListGroupItem className="w-100d-flex align-items-start justify-content-start py-4">
                  <div className="d-flex align-items-start justify-content-start">
                    <i className="bx bx-folder-open fs-1 text-primary mr-5"></i>
                    <Link to={`/gallery-images/${folder.id}`}>
                      <span className="fw-medium fs-5 text-dark custom-left-margin">
                        {folder.album_name}
                      </span>
                    </Link>
                  </div>
                <div className="mt-3 mb-3">
                <span className="fw-normal fs-6 text-dark">
                    {folder.details}
                  </span>
                </div>
                  <div className="w-100 d-flex justify-content-end ">
                    <span>{folder.up_date}</span>
                  </div>
                </ListGroupItem>
              </div>
            ))}
        </ListGroup>
      )}
    </div>
  )
}

export default FolderList
