import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid } from "store/Common/Actions"
import { getAttendanceDiv } from "store/Attendance/action"
import {
  getSubjectListStart,
  SubmitAccessRequestStart,
} from "store/SubjectRequest/action"
import { Card, CardBody, Col, Container, Label, Row, Input } from "reactstrap"
import DeleteModal from "./Modal"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"

const Home = () => {
  const dispatch = useDispatch()
  const { acadID } = useSelector(state => state.CommonReducer)
  const { divisions } = useSelector(state => state.AttendanceReducer)
  const { subjectList } = useSelector(state => state.SubjectRequest)
  const [Loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(false)
  const [div_id, setDivId] = useState(null)
  const [dmodel, setDmodel] = useState(false)
  const [divName, setDivName] = useState("")
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([])

  const [postData, setPostData] = useState({
    acadId: acadID.acadid,
    classDivId: div_id,
    subjecId: selectedSubjectIds,
  })

  const handleDivisionChange = e => {
    const selectedDivision = JSON.parse(e.target.value)
    setDivName(selectedDivision.name)
    setDivId(selectedDivision.id)
    setDmodel(true)
  }

  const onClickYes = () => {
    setSelected(true)
    setDmodel(false)
  }

  const onCloseClick = () => {
    setSelected(false)
    setDmodel(false)
  }

  useEffect(() => {
    if (!div_id) return
    dispatch(
      getSubjectListStart({
        acadId: acadID.acadid,
        classDivId: div_id,
      })
    )
  }, [acadID.acadid, div_id])

  // const handleCheckboxChange = subid => {
  //   setSelectedSubjectIds(prev =>
  //     prev.includes(subid) ? prev.filter(id => id !== subid) : [...prev, subid]
  //   )
  // }
  const handleCheckboxChange = id => {
    setSelectedSubjectIds(prevSelectedSubjectIds => {
      if (prevSelectedSubjectIds.includes(id)) {
        return prevSelectedSubjectIds.filter(subjectId => subjectId !== id)
      } else {
        return [...prevSelectedSubjectIds, id]
      }
    })
  }

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
    dispatch(getAttendanceDiv())
  }, [dispatch])

  useEffect(() => {
    setPostData({
      acadId: acadID.acadid,
      classDivId: div_id,
      subjectId: selectedSubjectIds,
    })
  }, [acadID.acadid, div_id, selectedSubjectIds])

  const handleSubmitRequest = async () => {
    if (!postData) return
    try {
      setLoading(true)
      const apiToken = JSON.parse(localStorage.getItem("Token"))
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_LIVE_API_URL}/api/teacher-subjectAccess/requestSubjectAccess`,
        data: postData,
        headers: {
          Authorization: apiToken,
        },
      }).then(response => {
        setLoading(false)
        console.log(response.data.message)
        toast.success(response.data.message)
        if (response.data.message === "Request Created Successfully!!") {
          setPostData({
            acadId: "",
            classDivId: "",
            subjectId: [],
          })
        }
      })

      // setSubjects(response.data)
    } catch (error) {
      setLoading(false)
      console.error("Error fetching subject list:", error)
    }
  }

  if (Loading) {
    return (
      <div className="page-content">
        <Spinners />
      </div>
    )
  }

  return (
    <>
      <DeleteModal
        show={dmodel}
        onCloseClick={onCloseClick}
        onClickYes={onClickYes}
        division={divName}
      />
      <div className="page-content">
          <Container>
        <Card className="shadow-lg rounded-lg">
              <CardBody>
              <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Request for Subject
            </h1>
                <Row>
                  <Col xl="12" md="12" sm="12" className="mt-1">
                    {/* <Label>Academic Year</Label> */}
                    <select className="form-select" disabled>
                      <option value="">{acadID?.academics}</option>
                    </select>
                  </Col>
                  <Col xl="4" className="mt-2">
                    {/* <Label>Select Class</Label> */}
                    <select
                      className="form-select"
                      onChange={handleDivisionChange}
                    >
                      <option value="">Select Divisions</option>
                      {divisions?.map(classDiv => (
                        <option
                          key={classDiv.id}
                          value={JSON.stringify({
                            id: classDiv.id,
                            name: classDiv.div_name,
                          })}
                        >
                          {classDiv.div_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  {selected && (
                    <Col xl="4" className="mt-2">
                      {subjectList.length > 0 && <Label>Select Subjects</Label>}
                      {subjectList.map(sub => (
                        <div key={sub.id}>
                          <input
                            type="checkbox"
                            id={sub.id}
                            className="form-check-input"
                            onChange={() => handleCheckboxChange(sub.id)}
                            checked={selectedSubjectIds.includes(sub.id)}
                          />
                          <Label
                            htmlFor={sub.id}
                            check
                            className="form-check-label"
                          >
                            {sub.subject}
                          </Label>
                        </div>
                      ))}
                    </Col>
                  )}
                </Row>

                <div className="d-flex mt-4 align-items-center justify-content-center">
                  <button
                    onClick={handleSubmitRequest}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
        <ToastContainer/>
     
    </>
  )
}

export default Home
