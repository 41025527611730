import React, { useState } from "react"
import {
  Container,
  Table,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
} from "reactstrap"
import moment from "moment"

const RemarksTable = ({ data, studentName, loading }) => {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Card className="shadow-sm border-0">
            {/* Modern Card Header */}
            <div
              className="d-flex"
              //   style={{
              //     background: "linear-gradient(90deg, #6a11cb, #2575fc)",
              //   }}
            >
              {/* <Badge
                color="primary"
                className="px-3 py-2 text-white"
                style={{
                  borderRadius: "12px",
                  fontWeight: "500",
                }}
              >
                Download Report
              </Badge> */}
              {/* <h5 className="mb-0">{studentName}</h5> */}
            </div>
            <CardBody>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "200px" }}
                >
                  <Spinner color="primary" />
                </div>
              ) : (
                <div className="table-responsive">
                  <Table
                    bordered
                    hover
                    responsive
                    className="table-modern table-striped bg-amber-100"
                  >
                    <thead>
                      <tr className="table-primary">
                        <th>Sr.No</th>
                        <th>Date</th>
                        <th style={{ whiteSpace: "nowrap" }}>Remarked By</th>
                        <th>Category</th>
                        <th>Remarks</th>
                        <th>Academics</th>
                        <th>Attachment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr
                          key={item.id}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#ffffff" : "#f2f2f2",
                          }}
                        >
                          <td>{index + 1}</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {moment(item.created_date).format("DD-MM-YYYY")}
                          </td>
                          <td>{item.Teacher}</td>
                          <td>{item.Remakrs_cat}</td>
                          <td>{item.remarks}</td>
                          <td>{item.academics}</td>
                          <td>
                            {item.attachment ? (
                              <a
                                href={item.attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none"
                              >
                                <Badge
                                  color="success"
                                  className="px-3 py-2 text-white"
                                  style={{
                                    borderRadius: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Download
                                </Badge>
                              </a>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default RemarksTable
