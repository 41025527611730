import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  CardImg,
  Button,
} from "reactstrap"
import moment from "moment"
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"

const Home = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const Data = state

  const [currentTime, setCurrentTime] = useState()
  const [visitor, setVisitor] = useState([])
  const [staff, setStaff] = useState([])
  const [startSec, setStartSec] = useState("")
  const [currentTime2, setCurrentTime2] = useState()
  const [startHour, setStartHour] = useState("")
  const [startMin, setStartMin] = useState("")
  const [Picture, setPicture] = useState()
  const [timePeriod, setTimePeriod] = useState("")
  const [mob, setMob] = useState("")
  const [Adhar, setAdhar] = useState("")
  const [avatar, setAvatar] = useState("")

  const [h, setH] = useState("")
  const [m, setM] = useState("")
  // Pre-fill form data state
  const [data, setData] = useState({
    file: "",
    firstName: "",
    mobileNo: "",
    adharCardNo: "",
    address: "",
    purpose: "",
    visitor_date: "",
    startHour: "",
    endHour: "",
    startMin: "",
    endMin: "",
    ampmStart: "",
    ampmEnd: "",
    visitorType: "",
    whom_to_meet_name: "",
    whom_to_meet_id: "",
  })

  const baseUrl = "https://marcopolo.prisms.in/api/visitor/"
  const token = JSON.parse(localStorage.getItem("Token"))

  // Pre-fill the form when Data is available
  useEffect(() => {
    if (Data && Data.length > 0) {
      const formData = Data[0] || {};
      setData(prev => ({
        ...prev,
        mobileNo: formData.mobile,
        adharCardNo: formData.aadhar_card_no ? formData.adhar_card_no : Adhar,
        purpose: formData.Visit_purpose,
        visitor_date: moment(formData.Date).format("YYYY-DD-MM"),
        visitorType: formData.Type,
        startHour:startHour,
        startMin: startMin,
        ampmStart: timePeriod,
      }))
    }
  }, [])

  useEffect(() => {
    setData(prev => ({
      ...prev,
      startHour: h || startHour,
      startMin: m || startMin,
      ampmStart: timePeriod,
    }))
  }, [h, m, startHour, startMin])



  

  const handleFileChange = e => {
    const file = e.target.files[0]
    setPicture(file)
    if (file) {
      setData(prev => ({
        ...prev,
        file: file,
      }))
      setAvatar(URL.createObjectURL(file))
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const timeString = new Date().toLocaleTimeString()
      const [hour, minute, second, secondWithPeriod] = timeString.split(/:| /)
      const [period] = secondWithPeriod.split(" ")
 
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

      setCurrentTime(formattedTime);
      setStartHour(hour)
      setStartMin(minute)
      setStartSec(second)
      setTimePeriod(period)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const getVisitorType = async () => {
    try {
      axios({
        method: "GET",
        url: `${baseUrl}get-visitor-type`,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setVisitor(res.data.data)
      })
    } catch (error) {}
  }

  const getStaffToMeet = async () => {
    try {
      axios({
        method: "GET",
        url: `${baseUrl}get-staff-to-meet`,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setStaff(res.data.data)
      })
    } catch (error) {}
  }

  useEffect(() => {
    getVisitorType()
    getStaffToMeet()
  }, [])

  const handleSubmitForm = async () => {
    if (
      !data.mobileNo ||
      data.mobileNo.length < 10 ||
      !data.firstName ||
      !data.adharCardNo ||
      !data.purpose ||
      !data.whom_to_meet_id ||
      !data.visitorType 
    ) {
      toast.error("Please enter all the required fields")
      return
    }

    const fm = new FormData()
    fm.append("firstName", data.firstName)
    fm.append("mobileNo", data.mobileNo)
    fm.append("adharCardNo", data.adharCardNo)
    fm.append("address", data.address)
    fm.append("purpose", data.purpose)
    fm.append("visitor_date", data.visitor_date)
    fm.append("startHour", data.startHour)
    fm.append("endHour", data.endHour)
    fm.append("endMin", data.endMin)
    fm.append("startMin", data.startMin)
    fm.append("ampmStart", data.ampmStart)
    fm.append("ampmEnd", data.ampmEnd)
    fm.append("entry_mode", "QR")
    fm.append("visitorType", data.visitorType)
    fm.append("whom_to_meet_name", data.whom_to_meet_name)
    fm.append("whom_to_meet_id", data.whom_to_meet_id)
    fm.append("files", Picture)
    fm.forEach((value, key) => {
      console.log(key, value);
    });
    try {
      await axios({
        method: "POST",
        url: `${baseUrl}submit-visitor-form`,
        data: fm,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }).then(res => {
        console.log(res.data.message)
        toast.success(res.data.message)
        navigate("/visitors")
      })
    } catch (error) {
      // Handle error
      console.error(error)
      toast.error("An error occurred while submitting the form.")
    }
  }
  const handleTimeChange = e => {
    const [hour, minute] = e.target.value.split(":")
    setH(hour)
    setM(minute)
    setCurrentTime2(e.target.value)
  }


  return (
    <div className="page-content">
      <Container>
        <Card className="p-4 shadow-sm">
          <Row className="d-flex justify-content-center flex-column align-items-center">
            <Col xs="auto" className="text-center">
              <div className="position-relative">
                <CardImg
                  src={
                    avatar ||
                    "https://ocisa.prisms.in/images/dashboard/student.png"
                  }
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "120px",
                    height: "120px",
                    objectFit: "cover",
                    border: "4px solid #007bff",
                  }}
                />
                <Input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ display: "none" }}
                  id="file-input"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="file-input"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "#007bff",
                    color: "white",
                    padding: "5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "2px solid white",
                  }}
                >
                  <i className="fa fa-camera" style={{ fontSize: "18px" }} />
                </label>
              </div>
            </Col>

            {/* Mobile No Field */}
            <Col xs="auto" className="mt-3 w-100">
              <InputGroup>
                <InputGroupText>
                  <i className="fa fa-phone text-primary"></i>
                </InputGroupText>
                <Input
                  type="tel"
                  maxLength={10}
                  placeholder="Enter Mobile No"
                  value={data.mobileNo}
                  onChange={e => {
                    const value = e.target.value.replace(/\D/g, "")
                    if (value.length <= 10) {
                      setMob(value)
                    }
                  }}
                />
              </InputGroup>
            </Col>

            {/* Name Field */}
            <Col xs="auto" className="mt-3 w-100">
              <InputGroup>
                <InputGroupText>
                  <i className="fa fa-user text-primary"></i>
                </InputGroupText>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  value={data.firstName}
                  onChange={e =>
                    setData(prev => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  }
                />
              </InputGroup>
            </Col>

            {/* Adhar No Field */}
            <Col xs="auto" className="mt-3 w-100">
              <InputGroup>
                <InputGroupText>
                  <i className="fa fa-poll text-primary"></i>
                </InputGroupText>
                <Input
                  type="tel"
                  maxLength={12}
                  placeholder="Enter Adhar No"
                  value={data.adharCardNo || Adhar}
                  onChange={e => {
                    const value = e.target.value.replace(/\D/g, "")
                    if (value.length <= 12) {
                      setAdhar(value)
                      setData(prev => ({
                        ...prev,
                        adharCardNo: value,
                      }))
                    }
                  }}
                />
              </InputGroup>
            </Col>

            {/* Purpose Field */}
            <Col xs="auto" className="mt-3 w-100">
              <Input
                type="text"
                placeholder="Enter Visit Purpose"
                value={data.purpose}
                readOnly
                onChange={e =>
                  setData(prev => ({ ...prev, purpose: e.target.value }))
                }
              />
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <Input
                type="date"
                value={data.visitor_date}
                onChange={e => {
                  setData(prev => ({
                    ...prev,
                    visitor_date: e.target.value,
                  }))
                }}
              />
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <Input
                type="time"
                value={currentTime2 || currentTime}
                onChange={handleTimeChange}
              />
            </Col>

            {/* Visitor Type Dropdown */}
            <Col xs="auto" className="mt-3 w-100">
              {/* <Label>Visitor Type</Label>
              <select
                className="form-select"
                value={data.visitorType}
                onChange={e => setData(prev => ({ ...prev, visitorType: e.target.value }))}
              >
                <option value="">Select Visitor</option>
                {visitor?.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.visitor_type}
                  </option>
                ))}
              </select> */}

              <Input
                type="text"
                placeholder=""
                value={data.visitorType}
                readOnly
                onChange={e =>
                  setData(prev => ({ ...prev, visitorType: e.target.value }))
                }
              />
            </Col>

            {/* Whom to Meet Dropdown */}
            <Col xs="auto" className="mt-3 w-100">
              <Label>Whom to Meet</Label>
              <select
                className="form-select"
                value={`${data.whom_to_meet_id},${data.whom_to_meet_name}`}
                onChange={e => {
                  const [staff_id, staff_name] = e.target.value.split(",")
                  setData(prev => ({
                    ...prev,
                    whom_to_meet_name: staff_name,
                    whom_to_meet_id: staff_id,
                  }))
                }}
              >
                <option value="">Select</option>
                {staff?.map(staff => (
                  <option
                    key={staff.staff_id}
                    value={`${staff.staff_id},${staff.name}`}
                  >
                    {staff.name}
                  </option>
                ))}
              </select>
            </Col>

            {/* Submit Button */}
            <Col className="d-flex justify-content-center align-items-center mt-3">
              <Button color="primary" onClick={handleSubmitForm}>
                Submit
              </Button>
            </Col>
          </Row>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  )
}

export default Home
