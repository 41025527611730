import React, { useEffect, useState } from "react"
import axios from "axios"
import { Card, CardBody, CardImg, CardTitle, CardSubtitle } from "reactstrap"
import Spinners from "components/Common/Spinner" // Assuming you have a Spinner component
import moment from "moment/moment"

const ReusableComponent = ({ title, details }) => {
  return (
    <div className="mb-3">
      <span className="text-primary fs-4 fw-medium">{title}</span>
      {Object.entries(details).map(([key, value]) => (
        <div
          className="d-flex align-items-start justify-content-between"
          key={key}
        >
          <div className="w-50">
            <span
              style={{
                lineHeight: 2,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {key}
            </span>
          </div>
          <div className="w-50">
            <span style={{ lineHeight: "1.2", fontSize: "14px" }}>{value}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

const Home = () => {
  const token = JSON.parse(localStorage.getItem("Token"))
  const [profileData, setProfileData] = useState([]) // Changed state to profileData
  const [loading, setLoading] = useState(true) // Added loading state
  const [error, setError] = useState(null) // Added error state

  useEffect(() => {
    const getTeacherProfile = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: "https://marcopolo.prisms.in/api/teacher-profile/get-profile",
          headers: {
            Authorization: token,
          },
        })

        setProfileData(res.data)
        setLoading(false)
      } catch (error) {
        setError("Failed to load profile data. Please try again.")
        setLoading(false)
      }
    }

    getTeacherProfile()

    const SchoolUrl = async () => {
      ///api/dashboard/get-school-url
      try {
        const res = await axios({
          method: "GET",
          url: "https://marcopolo.prisms.in/api/dashboard/get-school-url",
          headers: {
            Authorization: token,
          },
        })(res.data)
      } catch (error) {
        setError("Failed to load profile data. Please try again.")
        setLoading(false)
      }
    }
  }, [token])
  const replaceS3Url = url => {
    const baseUrl = "https://files.prisms.in/"
    const replacementBaseUrl = "https://s3.amazonaws.com/topschool-attachments/"

    const newUrl = url.replace(replacementBaseUrl, baseUrl)
    return newUrl
  }

  return (
    <div className="page-content">

      {loading && <Spinners />}
      {error && <div className="alert alert-danger">{error}</div>}

      {!loading && !error && profileData.length > 0 && (
        <Card className="shadow-lg mx-auto" style={{ maxWidth: "900px" }}>
          <CardBody>
            <div className="text-center mb-3 mt-3">
              <CardImg
                src={replaceS3Url(profileData[0].photo) || "https://ocisa.prisms.in/images/dashboard/student.png"}
                alt="Profile"
                className="rounded-circle"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />
              <h5 className="text-center text-primary mt-4">
                {profileData &&
                  profileData[0].first_name + " " + profileData[0].last_name}
              </h5>
              <h6 className="text-center">
                {profileData && profileData[0].type}
              </h6>
            </div>

            <ReusableComponent
              title="General Details"
              details={{
                Username: profileData[0].username,
                Email: profileData[0].email_id,
                "Mobile No": profileData[0].contact1,
                "Date of Birth": moment(profileData[0].dob).format("DD-MM-YY"),
                Address: `${profileData[0].address_line1}, ${profileData[0].address_line2}`,
                City: profileData[0].city,
                State: profileData[0].state,
                Country: profileData[0].country,
                Pincode: profileData[0].pincode,
              }}
            />
            {/* <ReusableComponent
              title="Class Teacher"
              details={{
                "Class/Div": profileData[0].type,
              }}
            /> */}
            <ReusableComponent
              title="Personal Details"
              details={{
                "Joining Date": moment(profileData[0].joining_date).format(
                  "DD-MM-YY"
                ),
                Experience: `${profileData[0].experience} Years`,
              }}
            />

            <div className="mb-3">
              <span className="text-primary fs-4 fw-medium">Subjects</span>
              {profileData[0].subjects.map((subject, index) => (
                <div
                  key={index}
                  className="d-flex align-items-start justify-content-between"
                >
                  <div className="w-50">
                    <span
                      style={{
                        fontWeight: 600,
                        lineHeight: 2,
                        fontSize: "14px",
                      }}
                    >
                      {subject.subject_name} ({subject.full_division_name})
                    </span>
                  </div>
                  <div className="w-50">
                    <span
                      style={{
                        lineHeight: 2,
                        fontSize: "14px",
                      }}
                    >
                      {subject.is_classteacher ? (
                        <span className="text-success fw-bold">
                          Class Teacher
                        </span>
                      ) : (
                        "Teacher"
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

export default Home
