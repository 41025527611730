import React, { useEffect, useState, useMemo } from "react"
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Alert,
  Row,
} from "reactstrap"
import axios from "axios"
import TableContainer from "components/Common/TableContainer"

import Spinners from "components/Common/Spinner"
import { ToastContainer, toast } from "react-toastify"
import Title from "common/Title"

const NotesUpload = () => {
  const [classList, setClassList] = useState([])
  const [Loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [Report, setReport] = useState()
  const [Academic, setAcademic] = useState([])
  const [AcadID, setAcadId] = useState([])
  const sid = localStorage.getItem("sid").replace(/"/g, "")
  const staff_id = localStorage.getItem("user_id").replace(/"/g, "")
  const token = JSON.parse(localStorage.getItem("Token"))

  const columns = useMemo(
    () => [
      {
        Header: () => <div style={{ color: "#111" }}>Roll No</div>,
        accessor: "roll_no",
        filterable: true,
      },
      {
        Header: () => <div style={{ color: "#111" }}>Name</div>,
        accessor: "student_name",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Division</div>,
        accessor: "div_name",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Status</div>,
        accessor: "STATUS",
        filterable: true,
        Cell: ({ value }) => {
          const className =
            value === "Present"
              ? "text-success"
              : value === "Absent"
              ? "text-danger"
              : ""
          return (
            <div className={`${className} fw-bold p-2 rounded`}>
              {value === "Present" ? "P" : value === "Absent" ? "A" : ""}
            </div>
          )
        },
      },
    ],
    [Report]
  )

  const [inputData, setInputData] = useState({
    batch_id: "",
    adate: "",
  })
  useEffect(() => {
    const getAcadId = async () => {
      try {
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/tscommon/getCurrentAcad",
          data: { sid: sid },
        }).then(response => {
          setAcademic(response.data[0].academics)
          setAcadId(response.data[0].acadid)
        })
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    getAcadId()
    axios({
      method: "GET",
      url: "https://marcopolo.prisms.in/api/notification/get-class-div",
      headers: {
        Authorization: token,
      },
    })
      .then(response => {
        setClassList(response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setInputData(prev => ({
      ...prev,
      adate: formattedDate,
    }))
  }, [])

  if (Loading) {
    return <Spinners />
  }

  const getAttendanceReport = async () => {
    try {
      // setLoading(true)
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/teacher-attendanceCenter/getStudentDailyAttendance",
        data: inputData,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        // console.log(res.data.message)
        setMessage(res.data.message)
        setReport(res.data.data)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (inputData.batch_id && inputData.batch_id) {
      getAttendanceReport()
    }
  }, [inputData.batch_id, inputData.adate])

  return (
    <div className="page-content">
      <Container>
        <Card className="shadow-lg rounded-lg">
          <CardBody>
            <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Attendance Report
            </h1>

            <Row>
              <Col xl="12" md="12" sm="12" className="mt-1">
                <Input
                  type="select"
                  name="class"
                  id="class"
                  value={inputData.batch_id}
                  onChange={e =>
                    setInputData(prev => ({
                      ...prev,
                      batch_id: e.target.value,
                    }))
                  }
                >
                  <option value="">Select Division</option>
                  {classList.map((data, idx) => (
                    <option key={idx} value={data.id}>
                      {data.div_name}
                    </option>
                  ))}
                </Input>
              </Col>

              <Col xl="12" md="12" sm="12" className="mt-2">
                <Input
                  type="date"
                  name="date"
                  id="date"
                  value={inputData.adate}
                  onChange={e =>
                    setInputData(prev => ({
                      ...prev,
                      adate: e.target.value,
                    }))
                  }
                />
              </Col>

              <FormGroup>
                <Col>
                  {Loading ? (
                    <Spinners />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={Report ? Report : []}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      // handleJobClicks={handleJobClicks}
                      isJobListGlobalFilter={false}
                      isPagination={false}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={100}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    />
                  )}
                </Col>
                {message && message !== "Records of students" && (
                  <Alert
                    color="danger"
                    className="text-center mt-4"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "medium",
                      fontSize: "xl",
                    }}
                  >
                    {message}
                  </Alert>
                )}
              </FormGroup>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <ToastContainer />
    </div>
  )
}

export default NotesUpload
