import React from "react";
import { Container, Card, CardBody, Input, Label, Button } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";

const Remark = () => {
  const location = useLocation();
  const { id } = useParams();


  return (
    <div className="page-content bg-light">
      <Container>
        <Card className="shadow border-0" style={{ borderRadius: "12px" }}>
          <CardBody>
            {/* Header Section */}
            <div
              className="d-flex justify-content-between align-items-center pb-3 border-bottom"
              style={{ marginBottom: "20px" }}
            >
              <div>
                <h5 className="fw-bold text-dark mb-1">Student Remarks</h5>
                <p className="text-muted mb-0" style={{ fontSize: "14px" }}>
                  Manage and submit remarks for student assignments.
                </p>
              </div>
              {location.state.file_location && (
                <a
                  href={location.state.file_location}
                  download
                  className="btn btn-sm btn-outline-primary d-flex align-items-center shadow-sm"
                  style={{ textDecoration: "none" }}
                >
                  <i className="bx bx-download me-1" style={{ fontSize: "16px" }} />
                  Download Assignment
                </a>
              )}
            </div>

            {/* Student Info Section */}
            <div className="mb-4">
              <h6 className="text-dark fw-bold mb-2">
                Name: <span className="text-secondary">{location.state.student_name}</span>
              </h6>
              <h6 className="text-dark fw-bold mb-0">
                Date:{" "}
                <span className="text-secondary">
                  {moment(location.state.submitted_date).format("DD-MM-YYYY")}
                </span>
              </h6>
            </div>

            {/* Remark Section */}
            <div className="mb-4">
              <Label className="fw-bold text-dark" for="remark">
                Remark
              </Label>
              <Input
                type="textarea"
                id="remark"
                value={location.state.remarks}
                className="mt-2 p-3"
                style={{
                  minHeight: "120px",
                  borderRadius: "10px",
                  border: "1px solid #ddd",
                  boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                }}
                placeholder="Enter your remarks here..."
              />
            </div>

            {/* Action Buttons */}
            <div className="text-end">
              <Button
                className="px-4 py-2 me-2"
                color="secondary"
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#f0f0f0",
                  color: "#333",
                  border: "1px solid #ccc",
                }}
              >
                Cancel
              </Button>
              <Button
                className="px-4 py-2"
                color={location.state.remarks ? "success" : "primary"}
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s",
                }}
              >
                {location.state.remarks ? "Update" : "Submit"}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Remark;
