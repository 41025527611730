import {
  GET_ATTEND_REPORT,
  GET_STUDLIST,
  GET_ATTEND_REPORT_FAILED,
  GET_ATTEND_REPORT_RESET,
  GET_ATTEND_REPORT_SUCCESS,
  GET_ATTENDANCE_DIV,
  GET_ATTENDANCE_DIV_FAILED,
  GET_ATTENDANCE_DIV_SUCCESS,
  GET_STUDLIST_FAIL,
  GET_STUDLIST_SUCCESS,
  MARK_ABSENT_STUD,
  MARK_ABSENT_STUD_FAILED,
  MARK_ABSENT_STUD_SUCCESS,
} from "./constants"

const initialState = {
  Loading: false,
  successMsg: "",
  errors: "",
  divisions: [],
  studList: [],
  absendStudList: [],
  AbsentList: [],
}

const AttendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_DIV:
    case GET_STUDLIST:
    case MARK_ABSENT_STUD:
    case GET_ATTEND_REPORT:
      return {
        ...state,
        Loading: true,
        successMsg: "",
        errors: "",
        divisions: [],
      }
    case GET_ATTENDANCE_DIV_SUCCESS:
      return {
        ...state,
        Loading: false,
        divisions: action.payload?.data ? action.payload?.data : [],
      }
    case GET_STUDLIST:
      return {
        ...state,
        Loading: true,
      }
    case GET_STUDLIST_SUCCESS:
      return {
        ...state,
        Loading: false,
        studList: action.payload[0].AllStudents,
        AbsentList: action.payload[1].absent_students,
      }
    case MARK_ABSENT_STUD_SUCCESS:
      return {
        ...state,
        Loading: false,
        successMsg: action.payload,
      }
    case GET_ATTEND_REPORT_SUCCESS:
      return {
        ...state,
        Loading: false,
        absendStudList: action.payload.data,
      }
    case GET_ATTENDANCE_DIV_FAILED:
    case GET_STUDLIST_FAIL:
    case MARK_ABSENT_STUD_FAILED:
    case GET_ATTEND_REPORT_FAILED:
      return {
        ...state,
        Loading: false,
        errors: action.payload,
      }

    case GET_ATTEND_REPORT_RESET:
      return {
        ...state,
        Loading: false,
        successMsg:"",
        divisions: [],
        studList: [],
        absendStudList: [],
        AbsentList: [],
      }

    default:
      return state
  }
}

export default AttendanceReducer
