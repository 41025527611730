import React, { useState, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import "./style.css"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import { Card, ListGroup, ListGroupItem, Container, CardBody } from "reactstrap"
import { replaceS3Url } from "components/Common/ReplaceS3Url"

const ImageList = () => {
  const { id } = useParams()
  const [images, setImages] = useState([])
  const [images1, setImages1] = useState([])
  const [gdata, setGdata] = useState(false)
  const [loadings, setLoadings] = useState(false)
  const [error, setErrors] = useState({})
  const [folders, setFolders] = useState([])
  const sid = localStorage.getItem("sid").replace(/"/g, "")

  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    const GetGalleryApi = async () => {
      setLoadings(true)
      try {
        const request1 = axios({
          method: "POST",
          url: "https://topschool.prisms.in/rest/index.php/staff_list.json",
          data: {
            fun_name: "getDocRepoByDetailsByFolderID",
            sid: sid,
            repoId: id,
          },
        })

        const request2 = axios({
          method: "POST",
          url: "https://topschool.prisms.in/rest/index.php/staff_list.json",
          data: {
            fun_name: "getSubDocRepoByDetailsByFolderID",
            sid: sid,
            repoId: id,
          },
        })

        const [response1, response2] = await Promise.all([request1, request2])

        setLoadings(false)
        setImages(response1.data.result)
        setImages1(response2.data.result)

        setDataSource([...response1.data.result, ...response2.data.result])
      } catch (error) {
        setLoadings(false)
        setErrors(error)
        console.log("Error fetching gallery data:", error)
      }
    }

    GetGalleryApi()
  }, [sid, id])

  // const handleDownload = (fileUrl) => {
  //   const link = document.createElement("a");
  //   link.href = replaceS3Url(fileUrl);
  //   link.target = "_blank";
  //   link.download = "";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const getFileNameWithoutExtension = s3Url => {
    const fileNameWithExt = replaceS3Url(s3Url).split("/").pop()
    const fileNameWithoutExt = fileNameWithExt.replace(/\.[^/.]+$/, "")
    const baseFileName = fileNameWithoutExt.replace(/_\d+$/, "")

    return baseFileName
  }

  if (loadings) {
    return <div className="page-content">
      <Container>
        <Spinners />
      </Container>
    </div>
  }

  if (!dataSource || dataSource.length === 0) {
    return (
      <div className="page-content">
        <Container>
          <CardBody>
            <Card>
              <h1 className="text-center mt-2 mb-4 fs-5">Not Available</h1>
            </Card>
          </CardBody>
        </Container>
      </div>
    )
  }

  return (
    <div className="page-content">
      <ListGroup>
        {dataSource.map((folder, index) => (
          <div key={index}>
            <ListGroupItem className="w-100d-flex align-items-start justify-content-start py-4">
              <div className="d-flex align-items-start justify-content-start">
                {folder.FILE && (
                  <i className="bx bxs-file-pdf fs-1 text-danger mr-5"></i>
                )}
                {folder.subfolder_name && (
                  <i className="bx bx-folder fs-1 text-primary mr-5"></i>
                )}
                {folder.subfolder_name && (
                  <Link to={`/document/sub/${folder.id}/${folder.folder_id}`}>
                    <span className="fw-medium fs-5 text-dark custom-left-margin">
                      {folder.subfolder_name}
                    </span>
                  </Link>
                )}
                {folder.FILE && (
                  <a
                    className="fw-medium fs-5 text-dark custom-left-margin"
                    style={{ cursor: "pointer" }}
                    href={replaceS3Url(folder.FILE)}
                    download
                    // onClick={handleDownload(folder.FILE)}
                  >
                    {getFileNameWithoutExtension(folder.FILE)}
                  </a>
                )}
              </div>
              <div className="w-100 d-flex justify-content-end ">
                <span>{folder.up_date}</span>
              </div>
            </ListGroupItem>
          </div>
        ))}
      </ListGroup>
    </div>
  )
}

export default ImageList
