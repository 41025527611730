import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { getFeesDetailsReset } from "store/actions"
//i18n
import { withTranslation } from "react-i18next"

// Import Css for dashboard
import "./Dashboard.css"
import { useDispatch,useSelector } from "react-redux"
import { toast } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { Link } from "react-router-dom"

const Dashboard = props => {
  //meta title
  document.title = "Prisms | Dashboard"
  const dispatch = useDispatch()
  // const regex = new RegExp(/https|http/g);

  const [sections, setSections] = useState([])

  const { dashIcons, Loading, errors, successMsg } = useSelector(
    state => state.DashReducer
  )

  // Add Module name for Testing only or if you are working on localhost
  const addModuleToSection = (sectionName, newModule) => {
    setSections(prevSections =>
      prevSections.map(section =>
        section.SectionName === sectionName
          ? {
              ...section,
              Modules: [...section.Modules, newModule],
            }
          : section
      )
    )
  }

  // const newModule = {
  //   module_name: "New Notification",
  //   url: "/new/notifications",
  //   icon: "far fa-gem",
  // }

  const transformData = data => {
    const transformedData = {}

    data.forEach(item => {
      const { section_name, module_name, url, icon, section_colour } = item
      if (!transformedData[section_name]) {
        transformedData[section_name] = {
          SectionName: section_name,
          SectionColor: section_colour,
          Modules: [],
        }
      }
      transformedData[section_name].Modules.push({ module_name, url, icon })
    })

    return Object.values(transformedData)
  }

  const addAlpha = (color, opacity) => {
    const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255)
    return color + _opacity.toString(16).toUpperCase()
  }

  useEffect(() => {
    if (Array.isArray(dashIcons) && dashIcons.length > 0) {
      let data = transformData(dashIcons)

      setSections(data)
    }
    // addModuleToSection("Teacher Academic", newModule)
    // addModuleToSection("Fees", newModule)
    // addModuleToSection("Admin", newModule)
  }, [dashIcons])

  useEffect(() => {
    if (errors && errors !== null) {
      toast.error(errors)
    }
    if (successMsg && successMsg !== null) {
      toast.success(successMsg)
    }
  }, [errors, successMsg])


  useEffect(()=>{

    dispatch(getFeesDetailsReset());
  },[]);
  return (
    <React.Fragment>
      <div className="page-content">
        {Loading ? (
          <Spinners />
        ) : (
          sections &&
          sections.length > 0 &&
          sections.map(section => (
            <Row
              className="border rounded-2 shadow bg-white mb-2 pb-3 "
              key={section.SectionName}
            >
              {/* <strong className="mb-2 mt-2 fs-">{section.SectionName}</strong> */}
              {/* <h5 className="mb-3 mt-2 text-bold">{section.SectionName}</h5> */}
              <div className="three">
                <h2>{section.SectionName}</h2>
              </div>
              {section.Modules.map(module => {
                const moduleRegex = new RegExp(/https|http|.in|.com/g)
                const isExternalLink = moduleRegex.test(module.url);
                // console.log('Loading module')
                // console.log(module.url);
                // console.log(isExternalLink);

                return (
                  <Col md={2} xs={4} key={module.module_name}>
                    <Link
                      className="d-flex flex-column align-items-center justify-content-center mt-2"
                      to={isExternalLink ? "/external-link" : module.url}
                      state={{ webLink: module.url }}
                    >
                      <div
                        className="iconContainer shadow-sm"
                        style={{
                          background: addAlpha(section.SectionColor, 0.1),
                          // border: `1px solid ${section.SectionColor}`
                        }}
                      >
                        <i
                          className={`${module.icon} dashIcons`}
                          style={{
                            color: section.SectionColor,
                          }}
                        ></i>
                      </div>
                      <span className="IconText"> {module.module_name} </span>
                    </Link>
                  </Col>
                )
              })}
            </Row>
          ))
        )}
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
