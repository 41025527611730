import React, { useEffect, useState, useMemo } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid } from "store/Common/Actions"

const Home = () => {
  const dispatch = useDispatch()
  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  let baseUrl = "https://marcopolo.prisms.in/api/examfillmarks"
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [academic, setAcademic] = useState([])
  const [acad_id, setAcad_id] = useState("")
  const [div, setDiv] = useState([])
  const [data, setData] = useState([])
  const [div_id, setDiv_id] = useState("")
  const [examList, setExamList] = useState([])
  const [exam_id, setExam_id] = useState("")
  const [subList, setSubList] = useState([])
  const [subject_id, setSubject_id] = useState("")
  const [marks, setMarks] = useState({})

  const [studentList, setStudentList] = useState([])
  const [parameters, setParameters] = useState([])
  const [para_id, setParaId] = useState("")
  const [submitMarks, setSubmitMarks] = useState()

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{ color: "#111", whiteSpace: "nowrap" }}>Roll No</div>
        ),
        accessor: "rollNo",
        filterable: true,
      },
      {
        Header: () => (
          <div style={{ color: "#111", whiteSpace: "nowrap" }}>
            Student Name
          </div>
        ),
        accessor: "student",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => (
          <div
            style={{
              width: "120px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
            }}
          >
            Fill Marks
          </div>
        ),
        accessor: "fillMarks",
        filterable: true,
        Cell: ({ value, row: { index } }) => {
          const handleChange = e => {
            let inputValue = e.target.value

            if (inputValue === "") {
              setData(prevData => {
                const updatedData = [...prevData]
                updatedData[index] = {
                  ...updatedData[index],
                  fillMarks: "",
                }
                return updatedData
              })
              return
            }

            const newValue = parseInt(inputValue, 10)

            if (isNaN(newValue)) {
              console.log("Not a valid number!")
              toast.error("Please enter a valid number")
              return
            }

            if (newValue < 0) {
              console.log("Negative value is not allowed!")
              toast.error("Marks cannot be negative")
              return
            }

            if (newValue > marks) {
              console.log(
                "Entered value exceeds max marks:",
                newValue,
                ">",
                marks
              )
              toast.error(
                `Mark should not be greater than the maximum marks: ${marks}`
              )
              return
            }

            setData(prevData => {
              const updatedData = [...prevData]
              updatedData[index] = {
                ...updatedData[index],
                fillMarks: newValue,
              }
              console.log(updatedData)
              return updatedData
            })
          }

          return (
            <input
              type="number"
              style={{ width: "90px" }}
              className="form-control"
              value={value !== undefined ? value : ""}
              onChange={handleChange}
            />
          )
        },
      },
    ],
    [marks, para_id, studentList]
  )

  // get-academic-year

  const getAcademicYear = async () => {
    try {
      await axios({
        method: "GET",
        url: `${baseUrl}/get-Academic-year`,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        console.log(res.data)
        setAcademic(res.data.data)
      })
    } catch (error) {
      console.log(error)
    }
  }
  ///get-parameters

  const getParameters = async () => {
    try {
      await axios({
        method: "POST",
        url: `${baseUrl}/get-parameters`,
        data: {
          acad_id: acadID.acadid,
          classdiv_id: div_id,
          exam_id: exam_id,
          subject_id: subject_id,
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setParameters(res.data.data)
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
    getAcademicYear()
  }, [para_id])

  useEffect(() => {
    const getClassDiv = async () => {
      try {
        await axios({
          method: "POST",
          url: `${baseUrl}/get-classdiv-list`,
          data: {
            acad_id: acad_id ? acad_id : acadID.acadid,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setDiv(res.data.data)
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (acad_id || acadID.acadid) {
      getClassDiv()
    }
    const getExamList = async () => {
      setLoading(true)
      try {
        await axios({
          method: "POST",
          url: `${baseUrl}/get-exam-list`,
          data: {
            acad_id: acad_id ? acad_id : acadID.acadid,
            classdiv_id: [div_id],
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setExamList(res.data.data)
          setLoading(false)
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    if (acad_id || (acadID && div_id)) {
      getExamList()
    }
    // Subject List

    const getSubjectList = async () => {
      try {
        await axios({
          method: "POST",
          url: `${baseUrl}/get-subject-list`,
          data: {
            exam_id: exam_id,
            acad_id: acad_id ? acad_id : acadID.acadid,
            classdiv_id: div_id,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setSubList(res.data.subjects)
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (acad_id || (acadID && div_id && exam_id)) {
      getSubjectList()
    }

    // Show  Exam list
    const getStudentList = async () => {
      setLoading(true)
      try {
        await axios({
          method: "POST",
          url: `${baseUrl}/show-fill-marks`,
          data: {
            exam_id: parseInt(exam_id),
            acad_id: parseInt(acad_id ? acad_id : acadID.acadid),
            classdiv_id: parseInt(div_id),
            subject_id: parseInt(subject_id),
            para_id: parseInt(para_id),
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setStudentList(res.data.students)
          setLoading(false)
        })
      } catch (error) {
        // console.log(error)
        setLoading(false)
      }
    }
    if (
      acad_id ||
      (acadID && div_id && exam_id && subject_id && para_id) ||
      submitted
    ) {
      getStudentList()
    }
    if ((acad_id, div_id, exam_id, subject_id)) {
      getParameters()
    }
  }, [acad_id, div_id, exam_id, para_id, subject_id, submitted, acadID])

  useEffect(() => {
    if (studentList && studentList.length > 0) {
      const data2 = studentList.map(student => ({
        rollNo: student.rollno,
        student: student.name,
        fillMarks: student.exams[0].marks,
        max_marks: student.exams[0].max,
        user_id: student.exams[0].user_id,
      }))

      setData(data2)
      if (data2.length > 0) {
        setMarks(data2[0].max_marks)
      }
    } else {
      setData([])
    }
  }, [studentList])

  useEffect(() => {
    if (data.length > 0) {
      const updatedMarks = data.map(student => ({
        user_id: student.user_id,
        marks: student.fillMarks,
      }))

      setSubmitMarks(prevSubmitMarks => ({
        ...prevSubmitMarks,
        marks: updatedMarks.map(student => student.marks),
        user_id: updatedMarks.map(student => student.user_id),
      }))
    }
  }, [data])

  const handleSubmitMarks = async e => {
    e.preventDefault()

    try {
      // console.log(exam_id)
      // console.log(acadID.acadid || acad_id)
      // console.log(subject_id)
      // console.log(para_id)
      // console.log(submitMarks)
      // console.log(submitMarks.marks)
      if (
        !exam_id ||
        !subject_id ||
        !para_id ||
        !submitMarks ||
        !submitMarks.marks
      ) {
        toast.error("Please enter all the required marks and details")
        return
      }

      setLoading(true)
      setSubmitted(false)
      const data = {
        exam_id: exam_id,
        acad_id: parseInt(acadID.acadid ? acadID.acadid : acad_id),
        classdiv_id: parseInt(div_id),
        subject_id: parseInt(subject_id),
        para_id: parseInt(para_id),
        ...submitMarks,
      }

      await axios({
        method: "POST",
        url: `${baseUrl}/submit-exam-fill-marks`,
        data: data,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        console.log(res.message)
        toast.success(res.data.message)
        setLoading(false)
        setSubmitted(true)
      })
    } catch (error) {
      console.error("Error submitting marks: ", error)
      setLoading(true)
      setSubmitted(false)
    }
  }
  //lock_marksentry

  // console.log(para_id)

  return (
    <div className="page-content">
      <div className="">
        <Container>
        <Card className="shadow-lg rounded-lg">
            <CardBody>
              {/* <h1 className="text-center mt-2 fs-4">Exam Fill Marks</h1> */}
              <h1
              className="text-center mb-4 fs-3"
              style={{ color: "#3c3c3c" }}
            >
              Exam Fill Marks
            </h1>
              <Row>
                <Col xl="12" md="12" sm="12">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => setAcad_id(e.target.value)}
                  >
                    <option value={acadID.acadid}>{acadID.academics}</option>
                    {academic &&
                      academic.length > 0 &&
                      academic.map((year, index) => (
                        <option key={year.acadid} value={year.acadid}>
                          {year.academics}
                        </option>
                      ))}
                  </select>
                </Col>
                <Col xl="4" className="mt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => setDiv_id(e.target.value)}
                  >
                    <option>Select Class</option>
                    {div.length > 0 &&
                      div.map((item, index) => (
                        <option key={item.id} value={item.id}>
                          {item.div_name}
                        </option>
                      ))}
                  </select>
                </Col>
                <Col xl="4" className="mt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => setExam_id(e.target.value)}
                  >
                    <option>Select Exam</option>
                    {examList &&
                      examList.length > 0 &&
                      examList.map((item, index) => (
                        <option key={item.id} value={item.id}>
                          {item.exam_name}
                        </option>
                      ))}
                  </select>
                </Col>

                <Col xl="4" className="mt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => setSubject_id(e.target.value)}
                  >
                    <option value="">Select Subject</option>
                    {subList &&
                      subList.length > 0 &&
                      subList.map((item, index) => (
                        <option key={item.id} value={item.id}>
                          {item.subject}
                        </option>
                      ))}
                  </select>
                </Col>

                <Col xl="4" className="mt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => setParaId(e.target.value)}
                  >
                    <option>Select Parameter</option>
                    {parameters &&
                      parameters.length > 0 &&
                      parameters.map((item, index) => (
                        <option key={item.id} value={item.para_id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </Col>

                <Col xl="4" className="mt-2">
                  <Label className="mt-2 text-danger">
                    Out of Marks :{" "}
                    {data && data.length > 0 ? data[0].max_marks : ""}
                  </Label>
                </Col>
              </Row>

              {loading ? (
                <Spinners />
              ) : (
                <TableContainer
                  columns={columns}
                  data={data}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  // handleJobClicks={handleJobClicks}
                  isJobListGlobalFilter={false}
                  isPagination={false}
                  iscustomPageSizeOptions={false}
                  isShowingPageLength={false}
                  customPageSize={100}
                  tableClass="table-bordered align-middle nowrap mt-2"
                  paginationDiv="col-sm-12 col-md-7"
                  pagination="pagination justify-content-end pagination-rounded"
                />
              )}
              {data && data.length > 0 && (
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmitMarks}
                  >
                    Submit
                  </button>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Home
