import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Spinner,
  Button,
  Badge,
} from "reactstrap"
import moment from "moment"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"

const LeaveReport = () => {
  const [report, setReport] = useState([])
  const [apiLoader, setApiLoader] = useState(false)

  const token = JSON.parse(localStorage.getItem("Token"))

  const getReport = async () => {
    setApiLoader(true)
    try {
      const response = await axios({
        method: "GET",
        url: "https://marcopolo.prisms.in/api/studentLeave/report",
        headers: {
          Authorization: token,
        },
      })

      setTimeout(() => {
        setReport(response.data.data)
        setApiLoader(false)
      }, 200)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getReport()
  }, [])

  const DateCard = ({ id, t, v, t1, v1, t2, v2, t3, v3, v5, status }) => {
    const [date, setDate] = useState("")

    useEffect(() => {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, "0")
      const day = String(currentDate.getDate()).padStart(2, "0")
      const formattedDate = `${year}-${month}-${day}`
      setDate(formattedDate)
    }, [])

    const handleCancel = async (e, id) => {
      e.preventDefault()
      setApiLoader(true)
      try {
        const response = await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/studentLeave/cancelLeave",
          data: {
            leave_id: id,
          },
          headers: {
            Authorization: token,
          },
        })

        setApiLoader(false)
        toast.success(response.data.message)
        getReport()
      } catch (error) {
        setApiLoader(false)
        if (error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error("An error occurred while canceling the leave.")
        }
      }
    }

    const canCancel = moment(v5).isSameOrAfter(moment(date))

    return (
      <Card className="mb-3">
        {apiLoader ? (
          <Spinner />
        ) : (
          <CardBody>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <span className="mt-1 fw-medium fs-5 text-start">{t}</span>
              <span className="mt-1 fs-5 text-dark">{v}</span>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <span className="mt-1 fw-medium fs-5 text-start">{t1}</span>
              <span className="mt-1 fs-5 text-dark">{v1}</span>
            </div>
            <div className="d-flex flex-column">
              <span className="mt-1 fw-medium fs-5 text-start">{t2}</span>
              <span className="mt-1 fs-5 text-dark">{v2}</span>
            </div>

            {v3 && (
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span className="mt-1 fw-medium fs-5 text-start">{t3}</span>
                <span className="mt-1 fs-5 text-dark">
                  <a href={v3} download title="Download Assignment">
                    <i
                      className="bx bx-download"
                      style={{ fontSize: "22px" }}
                    />
                  </a>
                </span>
              </div>
            )}
            {status == 0 ? (
              <>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <span className="mt-1 fw-medium fs-5 text-start">
                    Leave status
                  </span>
                  <Badge
                    color="danger"
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                    }}
                  >
                    Cancelled
                  </Badge>{" "}
                </div>
              </>
            ) : status == 1 ? ( // Check if status is 1
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span className="mt-1 fw-medium fs-5 text-start">
                  Leave status
                </span>
                <Badge
                  color="success"
                  style={{
                    pointerEvents: "none",
                    cursor: "default",
                  }}
                >
                  Applied
                </Badge>
              </div>
            ) : (
              ""
            )}

            {status != 0 && canCancel && (
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span className="mt-1 fw-medium fs-5 text-start">
                  Cancel Leave
                </span>
                <span className="mt-1 fs-5 text-dark">
                  <Badge color="danger" onClick={e => handleCancel(e, id)}>
                    Cancel
                  </Badge>
                </span>
              </div>
            )}
          </CardBody>
        )}
      </Card>
    )
  }

  return (
    <>
      <div className="page-content">
        <Container>
          <Card className="shadow-lg rounded-lg">
            <CardBody>
              <h1
                className="text-center mb-4 fs-3"
                style={{ color: "#3c3c3c" }}
              >
                Leave Report{" "}
              </h1>

              {apiLoader ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <Row>
                  {report &&
                    report.map((data, index) => {
                      const {
                        leave_applied_date,
                        leave_start_date,
                        leave_end_date,
                        leave_reason,
                        leave_attachment_path,
                        leave_status,
                      } = data

                      return (
                        <Card key={index} className="mb-3">
                          <DateCard
                            id={data.leave_id} // Use leave_id, not data.id
                            t={"Start Date"}
                            v={moment(leave_start_date).format("DD-MM-YYYY")}
                            t1={"End Date"}
                            v1={moment(leave_end_date).format("DD-MM-YYYY")}
                            t2={"Leave Reason"}
                            v2={leave_reason}
                            t3={"Attachment"}
                            v3={leave_attachment_path}
                            v5={leave_start_date}
                            status={leave_status}
                          />
                        </Card>
                      )
                    })}
                </Row>
              )}
            </CardBody>
          </Card>
          <ToastContainer />
        </Container>
      </div>
    </>
  )
}

export default LeaveReport
