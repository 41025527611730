import React, { useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap"

const Home = () => {
  const [notification, setNotification] = useState("")
  const [portals, setPortals] = useState("")
  const [userType, setUserType] = useState("")
  const [module, setModule] = useState("")
  const [errors, setErrors] = useState({})
  const [files, setFiles] = useState(null)
  const [fileError, setFileError] = useState("")

  const handlePortalsChange = e => {
    const input = e.target.value
    const formattedInput = input.replace(/(\.in)(?![\n])/g, "$1\n").trim()

    setPortals(formattedInput)
    if (errors.portals) {
      setErrors(prevErrors => ({ ...prevErrors, portals: null }))
    }
  }

  const handleUserTypeChange = e => {
    setUserType(e.target.value)
    if (errors.userType) {
      setErrors(prevErrors => ({ ...prevErrors, userType: null }))
    }
  }

  const handleModuleChange = e => {
    setModule(e.target.value)
    if (errors.module) {
      setErrors(prevErrors => ({ ...prevErrors, module: null }))
    }
  }

  const handleNotificationChange = e => {
    setNotification(e.target.value)
    if (errors.notification) {
      setErrors(prevErrors => ({ ...prevErrors, notification: null }))
    }
  }

  const handleSendNotification = () => {
    const newErrors = {}
    if (!portals.trim()) newErrors.portals = "Portals are required."
    if (!userType) newErrors.userType = "User type is required."
    if (!module) newErrors.module = "Module is required."
    if (!notification.trim())
      newErrors.notification = "Notification message is required."

    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0) {
      const formattedPortals = portals
        .split(/\n|,|;/)
        .map(portal => portal.trim())
        .filter(portal => portal)
        .join(",")

      console.log("Formatted Portals:", formattedPortals)
      console.log("Notification:", notification)
      console.log("User Type:", userType)
      console.log("Module:", module)

      alert(`Notification sent to portals: ${formattedPortals}`)
    }
  }

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      const fileType = file.type
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"]

      if (allowedTypes.includes(fileType)) {
        setFiles(file)
        setFileError("") // Clear any previous errors
      } else {
        setFileError("Only PNG, JPG, JPEG, or GIF images are allowed.")
        setFiles(null)
      }
    }
  }
  return (
    <Container>
      <Card className="py-0">
        <CardBody>
          <Row className="justify-content-center align-items-center">
            <Col md={6}>
              <h1
                className="text-center mb-2 fs-3"
                style={{ color: "#3c3c3c" }}
              >
                Notification
              </h1>
              <Form>
                <FormGroup>
                  <Label for="portalsText">Portals</Label>
                  <Input
                    type="textarea"
                    id="portalsText"
                    value={portals}
                    onChange={handlePortalsChange}
                    placeholder="Enter your portals here..."
                    invalid={!!errors.portals}
                  />
                  {errors.portals && (
                    <FormFeedback>{errors.portals}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="userType">Select User Type</Label>
                  <Input
                    type="select"
                    id="userType"
                    value={userType}
                    onChange={handleUserTypeChange}
                    invalid={!!errors.userType}
                  >
                    <option value="">Select User Type</option>
                    <option value="student">Student</option>
                    <option value="staff">Staff</option>
                  </Input>
                  {errors.userType && (
                    <FormFeedback>{errors.userType}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="module">Select Module</Label>
                  <Input
                    type="select"
                    id="module"
                    value={module}
                    onChange={handleModuleChange}
                    invalid={!!errors.module}
                  >
                    <option value="">Select Module</option>
                    <option value="/fees/payment">Fees</option>
                    <option value="/timetable">Time Table</option>
                    <option value="/notifications">Notification</option>
                    <option value="/feedback">Bus Tracker</option>
                    <option value="/student/gallery">Gallery</option>
                  </Input>
                  {errors.module && (
                    <FormFeedback>{errors.module}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="notificationText">Notification Message</Label>
                  <Input
                    type="textarea"
                    id="notificationText"
                    value={notification}
                    onChange={handleNotificationChange}
                    placeholder="Enter your notification here..."
                    required
                    invalid={!!errors.notification}
                  />
                  {errors.notification && (
                    <FormFeedback>{errors.notification}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="notificationText">Attachment</Label>
                  <Input
                    type="file"
                    id="fileInput"
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                    onChange={handleFileChange}
                    invalid={!!fileError}
                  />
                  {fileError && <FormFeedback>{fileError}</FormFeedback>}
                </FormGroup>
                <Button color="primary" onClick={handleSendNotification} block>
                  Send Notification
                </Button>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  )
}

export default Home
