import html2canvas from "html2canvas"
import moment from "moment"
import axios from "axios"
import React, { useEffect, useState, useRef } from "react"
import { Button, Spinner, Row, Table, Col } from "reactstrap"
import { saveAs } from "file-saver"
import jsPDF from "jspdf"
const PrintRecipt = () => {
  const [feesLoading, SetFeesLoading] = useState(false);
  const [schoolInfo, SetSchoolInfo] = useState()
  const captureRef = useRef()
  const queryParams = new URLSearchParams(location.search)

  const encodedToken = queryParams.get("n")
  const encodedPrintId = queryParams.get("p")
  const aca = queryParams.get("a")
  const token = atob(encodedToken)
  const printId = atob(encodedPrintId)
  const acadInfo = atob(aca)

  const [printRecipt, setPrintRecipt] = useState([])

  const calTotalFees = array => {
    // console.log(array)
    let totalFees = 0
    if (array && Array.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        totalFees += array[i].amount
      }
    }

    return totalFees
  }
  const live_Url = "https://marcopolo.prisms.in"

  const downloadPdf = () => {
    html2canvas(captureRef.current, {
      scale: 3,
      useCORS: true,
    }).then(canvas => {
      const imageData = canvas.toDataURL("image/png")

      const pdf = new jsPDF()
      const imgWidth = 180
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      pdf.addImage(imageData, "PNG", 10, 10, imgWidth, imgHeight)
      pdf.save("feesReceipt.pdf")
    })
  }

  const getAcadYear = async () => {
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/parent-feesdetails/paidfeesdetailsprint`,
        data: {
          printId: printId,
        },
        headers: {
          Authorization: token.replace(/"/g, ""),
        },
      }).then(response => {
        setPrintRecipt(response.data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getSchoolInfo = async () => {
    try {
      await axios({
        method: "GET",
        url: `${live_Url}/api/dashboard/get-school-info`,
        headers: {
          Authorization: token.replace(/"/g, ""),
        },
      }).then(response => {
        console.log(response.data)
        SetSchoolInfo(response.data)
      })
    } catch (error) {
      console.log(error)
    }
  }
  ///api/dashboard/get-school-info
  useEffect(() => {
    getSchoolInfo()
    getAcadYear()

    setTimeout(() => {
      downloadPdf()
    }, 1000)
  }, [])
  const ResuableComponent = ({ title, data }) => {
    return (
      <Col
        className="d-flex justify-content-between align-items-center fw-medium"
        style={{
          margin: "0 12px",
        }}
      >
        <div className="w-50">
          <span className="fs-6 mt-1">{title}</span>
        </div>
        <div className="w-50">
          <span className="fs-6 mt-1">{data}</span>
        </div>
      </Col>
    )
  }

  return (
    <div className="page-content h-100 px-1 bg-white">
      <div ref={captureRef}>
        <div className="LogoContainer">
          <img
            src={schoolInfo && schoolInfo[0].school_logo}
            alt={schoolInfo && schoolInfo[0].name}
            className="schoolLogo"
          />
          <span
            className="d-block text-dark"
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html:schoolInfo && schoolInfo[0].name }}
          />
        </div>
        <div className="my-3">
          <Row>
            <div>
              <ResuableComponent
                title={"Student Name:"}
                data={
                  printRecipt &&
                  Array.isArray(printRecipt?.data) &&
                  printRecipt?.data[0]?.name
                }
              />
              <ResuableComponent
                title={"Acadmic Year:"}
                data={acadInfo?.academics && acadInfo?.academics}
              />
              <ResuableComponent
                title={"Recipt No:"}
                data={
                  printRecipt &&
                  Array.isArray(printRecipt?.data) &&
                  printRecipt?.data[0]?.rcp_no
                }
              />
            </div>
          </Row>
        </div>
        {feesLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="">
              <Table className="">
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Category</th>
                    <th>date</th>
                    <th>amount</th>
                  </tr>
                </thead>
                <tbody>
                  {printRecipt &&
                    printRecipt.data?.map((data, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td>{data.category}</td>
                        <td>{moment(data.date).format("DD-MM-YYYY")}</td>
                        <td>{data.amount}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={2}>Total</th>
                    <th>{calTotalFees(printRecipt?.data)}</th>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PrintRecipt
