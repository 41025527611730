import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"
import {
  Badge,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap"
import { useParams, Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"

const ResuableComponent = ({ data: initialData }) => {
  const { id } = useParams()
  const assignment_id = id
  const [data, setData] = useState(initialData)
  const [modal, setModal] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [currentAttachment, setCurrentAttachment] = useState(null)
  const token = JSON.parse(localStorage.getItem("Token"))

  const toggle = () => setModal(!modal)

  const handleAttachmentClick = attachment => {
    setCurrentAttachment(attachment)
    toggle()
  }

  const handleRemarkChange = (id, value) => {
    setData(prevData =>
      prevData.map(item =>
        item.id === id ? { ...item, remarks: value } : item
      )
    )
  }
  const handleSubmit = async id => {
    const updatedItem = data.find(item => item.id === id)
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/Assignment/submit-remark",
        data: {
          assignment_id: assignment_id,
          student_id: updatedItem.id,
          remark: updatedItem.remarks,
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        toast.success(res.data.message)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  return (
    <div>
      {data &&
        data.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "#fff",
              borderRadius: "4px",
              marginTop: "5px",
              padding: "15px",
              position: "relative",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="w-100">
                <div className="d-flex flex-column">
                  <span className="mt-1 text-start text-dark d-flex align-items-center">
                    {item.student_name}
                    {item.location1 !== "0" &&
                      item.location !== "0" &&
                      item.location_old !== "0" && (
                        <i
                          className="bx bx-paperclip ms-2 text-primary"
                          style={{ fontSize: "18px", cursor: "pointer" }}
                          onClick={() => handleAttachmentClick(item)}
                        ></i>
                      )}
                  </span>

                  {item.submitted_date !== " " && (
                    <span className="d-flex justify-content-between mt-2 text-start align-items-center">
                      <div>
                        <span className="text-dark fw-medium">Date</span>:{" "}
                        {moment(item.submitted_date).format("DD-MM-YYYY")}
                      </div>
                    </span>
                  )}

                  {item.submitted_date !== " " && (
                    <div className="mt-2">
                      <Input
                        type="textarea"
                        value={item.remarks || ""}
                        onChange={e =>
                          handleRemarkChange(item.id, e.target.value)
                        }
                      />
                      <Button
                        style={{
                          border: "none",
                          backgroundColor: "#556ee6",
                        }}
                        className="btn-sm btn-rounded mt-2"
                        onClick={() => handleSubmit(item.id)}
                      >
                        {/* {item.remarks === "" ? "Submit" : "Update"} */}
                        Submit
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

      <ToastContainer />

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Attachments</ModalHeader>
        <ModalBody>
          {currentAttachment ? (
            <div className="d-flex flex-column">
              {currentAttachment.location && (
                <div className="mb-3">
                  <p>
                    <strong>Attachment 1</strong>
                  </p>
                  <a
                    href={currentAttachment.location}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-dark w-50 text-break"
                  >
                    <Badge
                      color="success"
                      className="px-3 py-2 text-white"
                      style={{
                        borderRadius: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Download
                    </Badge>
                  </a>
                </div>
              )}
              {currentAttachment.location1 && (
                <div className="mb-3">
                  <p>
                    <strong>Attachment 2</strong>
                  </p>
                  <a
                    href={currentAttachment.location1}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-dark w-50 text-break"
                  >
                    <Badge
                      color="success"
                      className="px-3 py-2 text-white"
                      style={{
                        borderRadius: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Download
                    </Badge>
                  </a>
                </div>
              )}
              {currentAttachment.location_old && (
                <div className="mb-3">
                  <p>
                    <strong>Attachment 3</strong>
                  </p>
                  <a
                    href={currentAttachment.location_old}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-dark w-50 text-break"
                  >
                    <Badge
                      color="success"
                      className="px-3 py-2 text-white"
                      style={{
                        borderRadius: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Download
                    </Badge>
                  </a>
                </div>
              )}
            </div>
          ) : (
            <p>No attachment available.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const Report = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"))
  const { id } = useParams()

  const getSubjectAccessReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/Assignment/view-assignment",
        data: {
          assignment_id: id,
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubjectAccessReport()
  }, [])

  return (
    <>
      <div className="page-content">
        <Container>
          <Card>
            <CardBody>
            <h1
              className="text-center mb-4 fs-3"
              style={{ color: "#3c3c3c" }}
            >
              Assignment
            </h1>
              {loading ? (
                <Spinners />
              ) : (
                <ResuableComponent data={data || []} id={id} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Report

// const handleSubmitRemark = async ({}) => {
//   setLoading(true)
//   try {
//     await axios({
//       method: "POST",
//       url: "https://marcopolo.prisms.in/api/Assignment/submit-remark",
//       data: {
//         assignment_id: id,
//       },
//       headers: {
//         Authorization: token,
//       },
//     }).then(res => {
//       setData(res.data.data)
//       setLoading(false)
//     })
//   } catch (error) {
//     console.error("Error fetching leave data:", error)
//     setLoading(false)
//   }
// }
